import { useEffect, useState } from 'react'
import './TeacherDetails.css'
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { MultiSelect } from 'chakra-multiselect'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getActivityByTeacherIdApi } from '../../apis/activitityApi/activityApi'
import { listAllGroups } from '../../apis/groupApi/GroupApi'
import { deleteTeacherApi, getTeacherByIdApi, updateTeacherApi } from '../../apis/teacherApi/teacherApi'
import Headbar from '../../components/Headbar'
import SearchComponent from '../../components/SearchComponent'
import SharedTablePagination from '../../components/SharedTablePagination'
import { phoneNumberFormatter } from '../../utils/phoneNumberFormatter'
import { buildMessage } from '../../utils/toastMiddleware'
import Page from '../Page'

function TeacherDetails() {
  const toast = useToast()
  const navigate = useNavigate()
  const location = useLocation()

  const { teacherId } = useParams()
  const schoolId = location.state ? location.state.schoolId : null

  const [teacherData, setTeacherData] = useState({})
  const [activitiesData, setActivitiesData] = useState([])
  const [activities, setActivities] = useState([])
  const [groupOptions, setGroupOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [filterText, setFilterText] = useState('')
  const [selectedTab, setSelectedTab] = useState('activities')
  const modalDisclosure = useDisclosure()
  const popoverDisclosure = useDisclosure()

  const fetchOptions = () => {
    return new Promise((resolve) => {
      Promise.all([getTeacherByIdApi(teacherId), getActivityByTeacherIdApi(teacherId), listAllGroups(schoolId)]).then(
        ([teacherResult, activitiesResult, groupsResult]) => {
          resolve({ teacherResult, activitiesResult, groupsResult })
        },
      )
    })
  }

  useEffect(() => {
    fetchOptions().then(({ teacherResult, activitiesResult, groupsResult }) => {
      setTeacherData(teacherResult)
      setActivitiesData(activitiesResult)
      setGroupOptions(
        groupsResult.map((turma) => {
          return { value: turma.name, label: turma.name, id: turma.id }
        }),
      )
      setLoading(false)
    })
  }, [])

  function fetchTeacher() {
    Promise.all([getTeacherByIdApi(teacherId)]).then(([teacherResult]) => {
      setTeacherData(teacherResult)
    })
  }

  useEffect(() => {
    if (teacherData) {
      setActivities(
        activitiesData
          ? activitiesData.filter((item) => {
              return (
                item.subject.toLowerCase().includes(filterText.toLowerCase()) ||
                item.theme.name.toLowerCase().includes(filterText.toLowerCase())
              )
            })
          : [],
      )
    }
  }, [filterText, activitiesData])

  function handleSearchInputChange(e) {
    setFilterText(e.target.value)
  }

  function HandleDetails({ activityId }) {
    return (
      <div className={'teste publicsans-normal-black-12px'}>
        {
          <Link to={`/detalhamento-atividade/${activityId}`}>
            <div className="atividade-txt-4-area-de-turmas">
              <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
            </div>
          </Link>
        }
      </div>
    )
  }

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  function GetModalEditTeacher() {
    const [editTeacherData, setEditTeacherData] = useState({
      name: teacherData.name,
      phoneNumber: teacherData.phoneNumber,
      groups: teacherData.groups,
    })

    const [groupInputValue, setGroupInputValue] = useState(
      teacherData?.schools?.flatMap((school) => school.groups.map((group) => group.name)),
    )
    const [nameError, setNameError] = useState(false)
    const [groupsError, setGroupsError] = useState(false)

    useEffect(() => {
      if (groupInputValue) {
        setEditTeacherData({
          ...editTeacherData,
          groups: groupInputValue.map((turma) => groupOptions.find((item) => turma === item.value)?.id),
        })
      }
    }, [groupInputValue])

    return (
      <Modal
        isOpen={modalDisclosure.isOpen}
        size={'2xl'}
        onClose={modalDisclosure.onClose}
        isCentered={true}
        closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-teacher'}>
            Editar Professor
          </ModalHeader>
          <ModalCloseButton color={'#0a41d6'} marginTop={'5px'} />
          <ModalBody>
            <VStack alignItems={'flex-start'} gap={'16px'}>
              <div className={`${nameError ? 'error-form' : ''} publicsans-bold-nevada-16px`}>Nome do professor*</div>
              <Input
                className={'input-default-style'}
                onChange={(e) => setEditTeacherData({ ...editTeacherData, name: e.target.value })}
                defaultValue={editTeacherData.name}
              />
              <div className={'publicsans-bold-nevada-16px'}>Número do professor</div>
              <Input
                className={'input-default-style'}
                onChange={(e) => {
                  const formattedValue = phoneNumberFormatter(e.target.value)
                  setEditTeacherData({ ...editTeacherData, phoneNumber: formattedValue })
                }}
                value={editTeacherData.phoneNumber}
              />
              <div className={`${groupsError ? 'error-form' : ''} publicsans-bold-nevada-16px`}>
                Turmas vinculadas ao professor
              </div>
              <Box w={'100%'} h={'100%'}>
                <MultiSelect
                  placeholder={loading ? 'Aguarde um momento...' : 'Selecionar turma'}
                  onChange={(e) => {
                    setGroupInputValue(
                      e.map((turma) => {
                        if (turma?.value) {
                          return turma.value
                        }
                        return turma
                      }),
                    )
                  }}
                  borderColor={'#d7e3fb'}
                  backgroundColor={'white'}
                  isDisabled={loading}
                  options={groupOptions}
                  value={groupInputValue}
                  w={'100%'}
                  height={'100%'}
                />
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter
            width={'100%'}
            alignSelf={'center'}
            gap={{ md: '32px', base: '8px' }}
            flexDir={{ md: 'row', base: 'column-reverse' }}>
            <Button
              backgroundColor={'#f9faff'}
              borderColor={'#648BF7'}
              border={'1px'}
              borderRadius={'8px'}
              color={'#062989'}
              height={'48px'}
              width={'100%'}
              onClick={modalDisclosure.onClose}>
              Cancelar
            </Button>
            <Button
              backgroundColor={'#0A41D6'}
              border={'1px'}
              borderRadius={'8px'}
              color={'#FFFFFF'}
              height={'48px'}
              width={'100%'}
              onClick={async () => {
                if (editTeacherData.name && editTeacherData.groups.length > 0) {
                  const result = await updateTeacherApi(editTeacherData, teacherData.id)

                  if (result.response?.status === 400) {
                    toast(
                      buildMessage('error', 'Erro ao atualizar professor', 'O professor deve pertencer ao menos a uma turma.'),
                    )
                  } else {
                    toast(buildMessage('success', 'Professor atualizado.'))
                  }

                  fetchTeacher()
                  modalDisclosure.onClose()
                } else {
                  setNameError(!editTeacherData.name)
                  setGroupsError(editTeacherData.groups < 1)
                }
              }}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  function HandleAtividadeColumn({ atividadeName }) {
    return <div className={'table-cell-container publicsans-normal-nevada-14px'}>{atividadeName}</div>
  }

  function HandleTemaColumn({ temaName }) {
    return <div className={'table-cell-container publicsans-normal-nevada-14px'}>{temaName}</div>
  }

  function HandleEntregasColumn({ item }) {
    if (!item) {
      return <div className={'table-cell-container'} />
    }
    const countSubmitted = item.amountEssaysSubmitted
    const countReviewed = item.amountEssaysReviewed
    const countClosed = item.amountEssaysClosed

    const submittedText = countSubmitted === 1 ? `${countSubmitted} submetida` : `${countSubmitted} submetidas`
    const reviewedText = countReviewed === 1 ? `${countReviewed} revisada` : `${countReviewed} revisadas`
    const closedText = countClosed === 1 ? `${countClosed} concluída` : `${countClosed} concluídas`

    return (
      <div className={'table-cell-container'}>
        <div className={'essay-status in-grading-essay-status publicsans-normal-nevada-14px'}>{submittedText}</div>
        <div className={'essay-status in-review-essay-status publicsans-normal-nevada-14px'}>{reviewedText}</div>
        <div className={'essay-status finished-essay-status publicsans-normal-nevada-14px'}>{closedText}</div>
      </div>
    )
  }

  function HandleTurmasVinculadas() {
    const allGroups = teacherData?.schools?.flatMap((school) => school.groups)

    return loading
      ? ''
      : allGroups.map((item, key) => {
          return (
            <Text className={'group-background'} whiteSpace={'nowrap'} key={key}>
              <p
                className="theme-name-detalhamento-teacher publicsans-normal-nevada-14px"
                onClick={() => navigate(`/detalhamento-turma/${item.id}`)}>
                {item.name}
              </p>
            </Text>
          )
        })
  }

  return (
    <div className="teacher-details-container screen">
      <VStack width={'100%'} alignSelf={'start'} gap={0}>
        <Page authPage={true} allowedUserType={'teacher independentteacher adm'} />
        <Headbar returnTo={-1} />
        <HStack
          width={'90%'}
          flexDir={{ md: 'row', base: 'column' }}
          gap={'16px'}
          justifyContent={'space-between'}
          className={'content-max-size'}>
          <VStack gap={5} className={'detalhamento-professor-body'}>
            <Box className="teacher-details-title-box">
              <VStack pt={5} pb={5} width={'100%'}>
                <HStack width={'100%'} justify={'space-between'}>
                  <VStack width={'100%'} alignItems={'flex-start'}>
                    <div className={'publicsans-bold-nevada-14px'}>PROFESSOR</div>
                    <div className="atividade-iii-unidade-2-detalhamento-teacher publicsans-normal-absolute-zero-24px">
                      {loading ? '' : teacherData.name}
                    </div>
                  </VStack>
                  <div className="editar-detalhamento-teacher">
                    <Box alignContent={'center'} className="edit-container-detalhamento-teacher">
                      <Text className="publicsans-bold-nevada-14px">Editar</Text>
                      <img
                        className="mode-edit-detalhamento-teacher"
                        src="/img/mode-edit@2x.png"
                        alt="Mode edit"
                        onClick={modalDisclosure.onOpen}
                      />
                      {modalDisclosure.isOpen && <GetModalEditTeacher />}
                    </Box>
                  </div>
                </HStack>
                <Spacer />
                <HStack
                  alignItems={'start'}
                  justifyContent={'space-between'}
                  width={'100%'}
                  flexDir={{ md: 'row', base: 'column' }}>
                  <HStack alignItems={'start'} flexDir={{ md: 'row', base: 'column' }}>
                    <Text alignSelf={'center'} className="publicsans-bold-nevada-10px">
                      TURMAS VINCULADAS
                    </Text>
                    <HandleTurmasVinculadas />
                  </HStack>
                  <HStack>
                    <div className={'publicsans-bold-nevada-14px'}> Apagar Professor?</div>
                    <div>
                      <Popover
                        isOpen={popoverDisclosure.isOpen}
                        onOpen={popoverDisclosure.onOpen}
                        onClose={popoverDisclosure.onClose}
                        placement="bottom-end"
                        closeOnBlur={false}>
                        <PopoverTrigger>
                          <Image src={'/img/deleteIcon.svg'} minWidth={'25px'} marginRight={'5px'} cursor={'pointer'} />
                        </PopoverTrigger>
                        <PopoverContent maxWidth={'300px'} width={['280px', '448px']} borderRadius={'10px'}>
                          <PopoverArrow />
                          <PopoverCloseButton marginTop={'7px'} color={'#0a41d6'} />
                          <PopoverHeader>
                            <div className={'text-popover'}>Confirme Remoção do professor</div>
                          </PopoverHeader>
                          <PopoverFooter alignSelf={'center'} marginTop={'50px'} width={'100%'}>
                            <HStack width={'100%'} flexDir={{ md: 'row', base: 'column-reverse' }}>
                              <Button
                                backgroundColor={'#f9faff'}
                                borderColor={'#648BF7'}
                                border={'1px'}
                                borderRadius={'8px'}
                                color={'#062989'}
                                height={'48px'}
                                width={'100%'}
                                onClick={popoverDisclosure.onClose}>
                                Cancelar
                              </Button>
                              <Button
                                backgroundColor={'#0A41D6'}
                                color={'white'}
                                width={'100%'}
                                height={'48px'}
                                onClick={async () => {
                                  await deleteTeacherApi(teacherData.id)
                                  navigate(-1)
                                }}>
                                {' '}
                                Confirmar Remoção{' '}
                              </Button>
                            </HStack>
                          </PopoverFooter>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </HStack>
                </HStack>
              </VStack>
            </Box>

            <div className="frame-2595-detalhamento-teacher">
              <div className="switch-detalhamento-teacher">
                <div
                  className={`frame-2469-detalhamento-teacher ${selectedTab === 'activities' ? 'tab-border' : ''}`}
                  onClick={() => changeSelectedTab('activities')}>
                  <div
                    className={`${selectedTab === 'activities' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                    Atividades Vinculadas
                  </div>
                </div>
              </div>
            </div>
            {selectedTab === 'activities' && (
              <Box zIndex={'auto'} width={'100%'} gap={5}>
                <div className="search-filter-detalhamento-teacher">
                  <SearchComponent filterText={filterText} handleSearchInputChange={handleSearchInputChange} />
                </div>
                {activities.length ? (
                  <SharedTablePagination
                    data={activities}
                    itemsPerPage={5}
                    columns={[
                      {
                        title: 'Atividade',
                        render: (itemData) => <HandleAtividadeColumn atividadeName={itemData.subject} />,
                      },
                      {
                        title: 'Tema',
                        render: (itemData) => <HandleTemaColumn temaName={itemData.theme.name} />,
                      },
                      {
                        title: 'Entregas',
                        render: (itemData) => <HandleEntregasColumn item={itemData} />,
                      },
                      {
                        title: 'Detalhes',
                        render: (itemData) => <HandleDetails activityId={itemData.id} />,
                      },
                    ]}
                  />
                ) : (
                  <Box
                    backgroundColor={'#f3f5ff'}
                    width={'100%'}
                    borderRadius={'12px'}
                    minHeight={'100px'}
                    maxWidth={'100%'}
                    px={2}
                    alignContent={'center'}
                    textAlign={'center'}>
                    <Text className={'publicsans-normal-nevada-16px'}>
                      Ainda não foram criadas atividades para este professor
                    </Text>
                  </Box>
                )}
              </Box>
            )}
          </VStack>
          <Box mt={{ md: 0, base: 3 }} mb={5} className="frame-2591-detalhamento-professor">
            <h1 className="title-1-detalhamento-teacher publicsans-normal-resolution-blue-24px">Contato</h1>
            <div>
              <div className={'publicsans-normal-nevada-16px'}>Email</div>
              <div>
                <HStack width={'100%'} marginTop={'8px'}>
                  <Image src={'/img/message-icon.svg'} />
                  <div className={'publicsans-normal-nevada-14px'}> {teacherData.email} </div>
                </HStack>
              </div>
            </div>
            <div>
              <div className={'publicsans-normal-nevada-16px'}>Telefone</div>
              <div>
                <HStack width={'100%'} marginTop={'8px'}>
                  <Image src={'/img/phone-icon.svg'} />
                  <div className={'publicsans-normal-nevada-14px'}>
                    {' '}
                    {teacherData.phoneNumber ? teacherData.phoneNumber : 'Não informado'}{' '}
                  </div>
                </HStack>
              </div>
            </div>
          </Box>
        </HStack>
      </VStack>
    </div>
  )
}

export default TeacherDetails
