import { useEffect, useRef, useState } from 'react'
import CabecalhoTabela from '../../components/CabecalhoTabela'
import CardTitle from '../../components/CardTitle'
import ProgressCard from '../../components/ProgressCard'
import ResumoDeRedacoes from '../../components/ResumoDeRedacoes'
import './InitialPageTeacher.css'
import { useIsHistoryEmpty } from '@/utils/useNavigationHooks'
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Select,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { listAllAcitivitiesFromSchool } from '../../apis/activitityApi/activityApi'
import { getEssaysByGroupIdListApi } from '../../apis/essayApi/essayApi'
import GroupWithoutActivityCard from '../../components/GroupWithoutActivityCard'
import Headbar from '../../components/Headbar'
import Menu from '../../components/Menu'
import NoContentContainer from '../../components/NoContentContainer'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import { StatusType } from '../../utils/StatusType'
import { essayStatus } from '../../utils/essayStatus'
import Page from '../Page'

function InitialPageTeacher() {
  const { loggedUser, status } = useLoggedUserStore()
  const navigate = useNavigate()

  const [submetida, setSubmetida] = useState([])
  const [revisada, setRevisada] = useState([])
  const [concluida, setConcluida] = useState([])
  const [resumoTurma, setResumoTurma] = useState([])
  const [groups, setGroups] = useState([])
  const [essays, setEssays] = useState([])
  const [activities, setActivities] = useState([])
  const [loading, setLoading] = useState(true)
  const [daysOfEssays, setDaysOfEssays] = useState(30)

  const bodyRef = useRef(null)
  const [hStackWidth, setHStackWidth] = useState('100%')

  const fetchInitialData = async () => {
    setLoading(true)

    const [activityResult] = await Promise.all([listAllAcitivitiesFromSchool(loggedUser.schoolId)])

    const loggedSchoolGroups = loggedUser.schools
      .filter((school) => school.id === loggedUser.schoolId)
      .flatMap((school) => school.groups)

    const essaysPerGroupResult = await getEssaysByGroupIdListApi({
      days: daysOfEssays,
      groups: loggedSchoolGroups.map((group) => group.id),
    })

    loggedSchoolGroups.map((group) => {
      group.essays = essaysPerGroupResult.filter((essay) => essay.groupId === group.id)
    })

    return { activityResult, essaysPerGroupResult, loggedSchoolGroups }
  }

  function handleInitialData() {
    fetchInitialData().then(({ activityResult, essaysPerGroupResult, loggedSchoolGroups }) => {
      setGroups(loggedSchoolGroups || [])
      setEssays(essaysPerGroupResult || [])
      setActivities(activityResult || [])

      setLoading(false)
    })
  }

  useIsHistoryEmpty()

  useEffect(() => {
    if (status === StatusType.COMPLETED) {
      handleInitialData()
    }
  }, [daysOfEssays, loggedUser.schoolId, status])

  useEffect(() => {
    handleRender()
  }, [loading])

  function handleRender() {
    handleEssaysOverall()
    resumoTurmas()
  }

  function navigationHomeTeacher(link) {
    navigate(link)
  }

  function mountTableRow() {
    return activities.slice(0, 4).map((activity, key) => {
      const activitiesSubmitted = activity.amountEssaysSubmitted
      const activitiesReviewed = activity.amountEssaysReviewed
      const activitiesClosed = activity.amountEssaysClosed

      const submittedText = activitiesSubmitted === 1 ? `${activitiesSubmitted} submetida` : `${activitiesSubmitted} submetidas`

      const reviewedText = activitiesReviewed === 1 ? `${activitiesReviewed} revisada` : `${activitiesReviewed} revisadas`

      const closedText = activitiesClosed === 1 ? `${activitiesClosed} concluída` : `${activitiesClosed} concluídas`

      return (
        <Tr key={key}>
          <Td className={'publicsans-normal-nevada-14px'} textAlign={'center'} whiteSpace={'break-spaces'}>
            {activity.subject}
          </Td>
          <Td className={'publicsans-normal-black-12px'} textAlign={'-webkit-center'} whiteSpace={'break-spaces'}>
            <div className={'background-green'}>{activity.theme.name}</div>
          </Td>
          <Td>
            <div className={'deliveries-container'}>
              <div className={'essay-status in-grading-essay-status publicsans-normal-nevada-14px'}>{submittedText}</div>
              <div className={'essay-status in-review-essay-status publicsans-normal-nevada-14px'}>{reviewedText}</div>
              <div className={'essay-status finished-essay-status publicsans-normal-nevada-14px'}>{closedText}</div>
            </div>
          </Td>
          <Td alignContent={'center'} textAlign={'-webkit-center'}>
            <Box
              onClick={() => {
                navigationHomeTeacher(`/detalhamento-atividade/${activity.id}`)
              }}>
              <Image className="ic-arrowright-1" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" width={'24px'} />
            </Box>
          </Td>
        </Tr>
      )
    })
  }

  function calculoRedacoesStatus(statusDesejado) {
    return essays.filter((essay) => essay.status === statusDesejado).length
  }

  function calculoRedacoesStatusPorTurma(statusDesejado, group) {
    return group.essays.filter((essay) => essay.status === statusDesejado).length
  }

  function handleEssaysOverall() {
    setSubmetida(
      <ResumoDeRedacoes
        folderIcon={'/img/folder-icon.png'}
        status={essayStatus['20']}
        daysOfEssays={daysOfEssays}
        number={calculoRedacoesStatus(20)}
        linkReturnTo={'/inicio-professor'}
      />,
    )

    setRevisada(
      <ResumoDeRedacoes
        folderIcon={'/img/folder-pen-icon.png'}
        status={essayStatus['30']}
        daysOfEssays={daysOfEssays}
        number={calculoRedacoesStatus(30)}
        linkReturnTo={'/inicio-professor'}
      />,
    )

    setConcluida(
      <ResumoDeRedacoes
        folderIcon={'/img/folder-ok-icon.png'}
        status={essayStatus['40']}
        daysOfEssays={daysOfEssays}
        number={calculoRedacoesStatus(40)}
        linkReturnTo={'/inicio-professor'}
      />,
    )
  }

  function calculoRedacoesTotais(group) {
    return group.essays.length
  }

  function calculoProgressBar(redacoes, redacoesTotais) {
    const result = (redacoes / redacoesTotais) * 100
    return result
  }

  function resumoTurmas() {
    const result = groups.map((group, key) => {
      if (!group.essays || group.essays?.length === 0) {
        return (
          <div className="turma-card" key={key}>
            <GroupWithoutActivityCard groupName={group.name} key={key} groupId={group.id} />
          </div>
        )
      }

      const redacoesStatusSubmetidas = calculoRedacoesStatusPorTurma(20, group)
      const redacoesStatusRevisadas = calculoRedacoesStatusPorTurma(30, group)
      const redacoesStatusConcluidas = calculoRedacoesStatusPorTurma(40, group)
      const redacoesTotais = calculoRedacoesTotais(group)
      const progressBarBlue = calculoProgressBar(redacoesStatusSubmetidas, redacoesTotais)
      const progressBarYellow = calculoProgressBar(redacoesStatusRevisadas, redacoesTotais)
      const progressBarGreen = calculoProgressBar(redacoesStatusConcluidas, redacoesTotais)
      return (
        <VStack
          borderRadius="lg"
          minWidth="300px"
          align="start"
          alignContent="center"
          overflow="hidden"
          gap={0}
          boxShadow="xl"
          key={key}>
          <CardTitle groupName={group.name} groupId={group.id} linkReturnTo={'/inicio-professor'} />
          <VStack p={6} gap={6} bgColor="white" height="100%" width="100%">
            <ProgressCard
              progressPercentageBlue={progressBarBlue}
              progressPercentageYellow={progressBarYellow}
              progressPercentageGreen={progressBarGreen}
              redacoesSubmetidas={redacoesStatusSubmetidas}
              redacoesRevisadas={redacoesStatusRevisadas}
              redacoesConcluidas={redacoesStatusConcluidas}
              redacoesTotal={redacoesTotais}
            />
            <Box width={'100%'}>
              <Center>
                <Button
                  className="publicsans-bold-white-14px"
                  variant={'primary'}
                  alignSelf={'center'}
                  w={'100%'}
                  height={'45px'}
                  onClick={() => {
                    navigationHomeTeacher(`/detalhamento-turma/${group.id}`, { state: {} })
                  }}>
                  Ver detalhes
                </Button>
              </Center>
            </Box>
          </VStack>
        </VStack>
      )
    })

    if (result.length) {
      const maxChildren = getMaxChildren()

      const displayedChildren = result.slice(0, maxChildren)

      setResumoTurma(
        <SimpleGrid px={4} width="100%" templateColumns="repeat(auto-fill, minmax(300px,  1fr))" spacing="30px">
          {displayedChildren}
        </SimpleGrid>,
      )
    } else {
      setResumoTurma(
        <Text className={'publicsans-normal-gray-14px'} width={'100%'} textAlign={'center'}>
          Ainda não há turmas cadastradas.
        </Text>,
      )
    }
  }

  function getMaxChildren() {
    const childWidth = 360
    const gapWidth = 24

    const availableWidth = Number.parseInt(hStackWidth, 10)
    const totalChildWidth = childWidth + gapWidth

    return Math.floor((availableWidth + gapWidth) / totalChildWidth)
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'lightblue' : null,
    }),
    control: (provided) => ({
      ...provided,
    }),
  }

  useEffect(() => {
    const updateWidth = () => {
      if (bodyRef.current) {
        setHStackWidth(`${bodyRef.current.offsetWidth}px`)
      }
    }

    updateWidth()
    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  return (
    <div className="inicio screen">
      <HStack width={'100%'} height={'100%'} gap={0}>
        <Menu />
        <VStack width={'100%'} maxHeight={'100vh'} alignSelf={'start'} overflow={'auto'} gap={0}>
          <Headbar hideHeaderElements={true} />
          <Page authPage={true} allowedUserType={'teacher monitor independentteacher'}>
            <Box className="body-no-right">
              <Box className="header">
                <Text fontSize={{ md: '40px', base: '30px' }} className="publicsans-normal-absolute-zero-40px">
                  {'Olá, Professor!'}
                </Text>
                <Box width={'100%'} className="publicsans-normal-nevada-20px">
                  <HStack
                    flexDir={{ md: 'row', base: 'column' }}
                    w={'100%'}
                    alignItems={'start'}
                    justifyContent={'space-between'}>
                    <Text width={'100%'} fontSize={{ md: '20px', base: '17px' }}>
                      O que vamos corrigir hoje?
                    </Text>
                    <Select
                      onChange={(e) => {
                        setDaysOfEssays(Number.parseInt(e.target.value))
                      }}
                      borderColor={'#d7e3fb'}
                      backgroundColor={'white'}
                      styles={customStyles}
                      isDisabled={loading}
                      defaultValue={30}
                      maxW={'120px'}>
                      <option className={'select-days-text'} value={30}>
                        {'30 dias'}
                      </option>
                      <option className={'select-days-text'} value={90}>
                        {'90 dias'}
                      </option>
                      <option className={'select-days-text'} value={180}>
                        {'180 dias'}
                      </option>
                      <option className={'select-days-text'} value={365}>
                        {'365 dias'}
                      </option>
                    </Select>
                  </HStack>
                </Box>
              </Box>
              <div className="status-initial-page">
                {submetida}
                {revisada}
                {concluida}
              </div>
              <div className="informacoes-gerais-tela-inicial">
                <CabecalhoTabela text={'Turmas'} textLink={'Ver mais'} link={'/area-de-turmas'} />
                {resumoTurma}
              </div>
              <div ref={bodyRef} className="atividades-em-andamento">
                <CabecalhoTabela text={'Atividades em andamento'} textLink={'Ver mais'} link={'/area-de-atividades'} />
                <div className="tabela-atividades-1">
                  {activities.length !== 0 ? (
                    <TableContainer width={'100%'}>
                      <Table variant={'simple'}>
                        <Thead>
                          <Tr backgroundColor={'#f3f5fe'}>
                            <Th flex={1} textAlign={'center'}>
                              Atividade
                            </Th>
                            <Th flex={1} textAlign={'center'}>
                              Tema
                            </Th>
                            <Th flex={0.5} textAlign={'center'}>
                              <HStack w={'100%'} className={'tabela-title-13'} justifyContent={'center'}>
                                <div>Redações</div>
                                <Tooltip label="Redações que foram escritas, corrigidas, revisadas ou finalizadas são consideradas como entregues.">
                                  <Icon />
                                </Tooltip>
                              </HStack>
                            </Th>
                            <Th width={'84px'} textAlign={'center'}>
                              Detalhes
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>{mountTableRow()}</Tbody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div className="tabela-01-1">
                      <NoContentContainer>{'Ainda não há atividades associadas a esse professor.'}</NoContentContainer>
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </Page>
        </VStack>
      </HStack>
    </div>
  )
}

export default InitialPageTeacher
