import { Button, HStack, Image, Spacer, Text } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

type AreaHeader = {
  title: string
  rightSidebar?: ReactNode
  button?: {
    onClick?: () => void
    isVisible: boolean
    navigateTo?: string
    label: string
  }
}

export const AreaHeader = (props: AreaHeader) => {
  const { title, rightSidebar, button } = props
  const navigate = useNavigate()

  return (
    <HStack
      w={'100%'}
      bgColor="brand.600"
      borderRadius="lg"
      gap={{ base: 2, md: 6 }}
      p={6}
      flexDir={{ base: 'column', md: 'row' }}>
      <HStack w={'100%'} alignItems={'center'} position="relative">
        <Text
          width={'100%'}
          textAlign={{ base: 'center', md: 'start' }}
          fontSize={{ base: '25px', md: '40px' }}
          lineHeight={'115%'}
          className="publicsans-normal-absolute-zero-40px">
          {title}
        </Text>
        {rightSidebar}
      </HStack>

      <Spacer />
      {button?.isVisible && (
        <Button
          variant="primary"
          gap="12px"
          padding="25px"
          width="100%"
          maxW="200px"
          onClick={button.navigateTo ? () => navigate(button.navigateTo ?? '') : button.onClick}>
          <Image className="ic-add" src={'/img/ic-add-2@2x.png'} alt="ic-add" />
          <Text width={'100%'} className="inter-bold-zircon-14px">
            {button.label}
          </Text>
        </Button>
      )}
    </HStack>
  )
}
