import { create } from 'zustand'

export enum STEP_SCHOOL {
  ABOUT = 1,
  ADDRESS = 2,
  CREATED = 3,
}

const useCriarEscolaStore = create((set) => ({
  currentStep: 1,
  criarEscolaData: {
    schoolId: '',
    name: '',
    documentNumber: '',
    address: {},
  },

  setCurrentStep: (step) => set({ currentStep: step }),
  setCriarEscolaData: (data) =>
    set((state) => ({
      criarEscolaData: { ...state.criarEscolaData, ...data },
    })),
  setCriarEnderecoEscolaData: (data) =>
    set((state) => ({
      criarEscolaData: {
        ...state.criarEscolaData,
        address: { ...state.criarEscolaData.address, ...data },
      },
    })),
  cleanCriarEscolaData: () => set({ criarEscolaData: { address: {} }, currentStep: 1 }),
}))

export default useCriarEscolaStore
