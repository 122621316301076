import { useState } from 'react'
import './GetModalEditTurma.css'
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { getGroupInformation, putEditGroup } from '../../apis/groupApi/GroupApi'

function GetModalEditTurma({ modalDisclosure, turmasData, setTurmasData, loading, setLoading }) {
  const location = useLocation()

  const schoolId = location.state ? location.state.schoolId : null

  async function fetchGroup() {
    await Promise.all([getGroupInformation(turmasData.id, schoolId)]).then(([groupInformation]) => {
      setTurmasData(groupInformation)
    })
  }

  const maxCharacteresDescription = 200
  const maxCharacteresName = 50

  const [editTurmaData, setEditTurmaData] = useState({
    groupId: turmasData?.id,
    name: turmasData?.name,
    description: turmasData?.description,
    shift: turmasData?.shift,
  })

  const [nameError, setNameError] = useState('')
  const [descriptionError, setDescriptionError] = useState('')

  function validateInputs() {
    let isValid = true

    if (!editTurmaData.name || editTurmaData.name.length > 50) {
      setNameError('O nome da turma é obrigatório e deve ter no máximo 50 caracteres.')
      isValid = false
    } else {
      setNameError('')
    }

    if (editTurmaData.description.length > maxCharacteresDescription) {
      setDescriptionError(`A mensagem personalizada deve ter no máximo ${maxCharacteresDescription} caracteres.`)
      isValid = false
    } else {
      setDescriptionError('')
    }

    return isValid
  }

  return (
    <Modal isOpen={modalDisclosure.isOpen} onClose={modalDisclosure.onClose} isCentered={true} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent width={['300px', '100%']}>
        <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-atividade'}>
          Editar Turma
        </ModalHeader>
        <ModalCloseButton color={'#0a41d6'} marginTop={'5px'} />
        <ModalBody>
          <VStack alignItems={'flex-start'} gap={'16px'}>
            <div className={'publicsans-bold-nevada-16px'}>Nome da turma*</div>
            <Box position="relative" w={'100%'} h={'100%'} backgroundColor={'white'}>
              <Input
                className={`input-default-style ${nameError ? 'error-input' : ''}`}
                onChange={(e) => setEditTurmaData({ ...editTurmaData, name: e.target.value })}
                defaultValue={editTurmaData.name}
              />
              <Text fontSize="sm" position="absolute" bottom="5px" right="20px" color="#a9a8a8" fontFamily={'Public Sans'}>
                {editTurmaData.name ? editTurmaData.name.length : 0}/{maxCharacteresName}
              </Text>
            </Box>
            {nameError && <Text color="red">{nameError}</Text>}
            <div className={'publicsans-bold-nevada-16px'}>Mensagem Personalizada</div>
            <Box position="relative" w={'100%'} h={'100%'} backgroundColor={'white'}>
              <Textarea
                placeholder=""
                defaultValue={editTurmaData.description}
                onChange={(e) => setEditTurmaData({ ...editTurmaData, description: e.target.value })}
                resize="vertical"
                w={'100%'}
                h={'100%'}
                minHeight={'150px'}
                borderColor={descriptionError ? 'red' : '#d7e3fb'}
                className={descriptionError ? 'error-input' : ''}
              />
              <Text fontSize="sm" position="absolute" bottom="5px" right="20px" color="#a9a8a8" fontFamily={'Public Sans'}>
                {editTurmaData.description ? editTurmaData.description.length : 0}/{maxCharacteresDescription}
              </Text>
            </Box>
            {descriptionError && <Text color="red">{descriptionError}</Text>}
          </VStack>
        </ModalBody>

        <ModalFooter
          alignSelf={'center'}
          width={'100%'}
          gap={{ md: '32px', base: '8px' }}
          flexDir={{ md: 'row', base: 'column-reverse' }}>
          <Button variant={'outline'} height={'48px'} width={'100%'} isLoading={loading} onClick={modalDisclosure.onClose}>
            Cancelar
          </Button>
          <Button
            variant={'primary'}
            height={'48px'}
            width={'100%'}
            isLoading={loading}
            onClick={async () => {
              if (validateInputs()) {
                try {
                  setLoading(true)
                  await putEditGroup(editTurmaData, schoolId)
                  await fetchGroup()
                } finally {
                  setLoading(false)
                  modalDisclosure.onClose()
                }
              }
            }}>
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default GetModalEditTurma
