import { SelectedStep } from '@/components/FormSteps/SelectedStep'
import { usePostApiV1SchoolNewAdm } from '@/services/school'
import { usePostApiV1TeacherIndependentTeacher } from '@/services/teacher'
import type { NewAdmAnonymousViewModel, NewIndependentTeacherViewModel } from '@/services/types'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Input,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import type { UserData } from 'src/pages/SignUpPage'
import * as yup from 'yup'
import IlustracaoLogin from '../../components/IlustracaoLogin'
import { inputCNPJMask } from '../../utils/inputMasks'

const validationSchema = yup.object({
  nomeInstituicao: yup.string().required('Nome da instituição escolar é obrigatório'),
  documentNumber: yup.string().cnpj().required(),
  institution: yup.number(),
})
type Form = yup.InferType<typeof validationSchema>

type CadastroStep3Props = {
  userData: UserData
  handleReturn: () => void
  handlingNextStep: (userData?: UserData) => void
}

const SignUpStep3 = ({ handleReturn, handlingNextStep, userData }: CadastroStep3Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const postIndependentTeacher = usePostApiV1TeacherIndependentTeacher({
    mutation: {
      onSuccess: () => handlingNextStep(),
    },
  })

  const postADMTeacher = usePostApiV1SchoolNewAdm({
    mutation: {
      onSuccess: () => handlingNextStep(),
    },
  })

  const isLoading = postIndependentTeacher.isPending || postADMTeacher.isPending

  const onSubmit = (form: Form) => {
    if (userData.userType === 'Professor') {
      const data: NewIndependentTeacherViewModel = {
        ...userData,
        ...form,
        email: userData.email || '',
        name: userData.name || '',
      }
      return postIndependentTeacher.mutate({ data })
    }

    const data: NewAdmAnonymousViewModel = {
      institution: form.institution,
      documentNumber: form.documentNumber || '',
      schoolName: form.nomeInstituicao || '',
      phoneNumber: userData.phoneNumber || '',
      email: userData.email || '',
      name: userData.name || '',
    }
    postADMTeacher.mutate({ data })
  }

  return (
    <HStack width={'100%'} height={'100%'} justifyContent={'center'}>
      <Box flex={1} height="100%" maxW="750px" display={{ base: 'none', md: 'block' }}>
        <IlustracaoLogin />
      </Box>
      <Box flex={1} alignSelf={{ md: 'center', base: 'start' }} marginLeft="16px" marginTop={{ md: '32px', base: '0px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box maxWidth="700px">
            <Box alignContent={'start'}>
              <HStack flexDir={{ xl: 'row', md: 'column' }} justifyContent={{ md: 'start', base: 'center' }}>
                <Text className="publicsans-bold-absolute-zero-40px">Cadastre-se</Text>
                <Text className="span2-cadastro" display={{ base: 'none', md: 'block' }}>
                  no Correção Online
                </Text>
              </HStack>
            </Box>
            <VStack className="content-3-cadastro" alignItems={'start'} gap={0}>
              <HStack className="concluido-cadastro-step-3" ml={{ md: '4px', base: 2 }}>
                <Image width={'42px'} height={'42px'} src="/img/frame-2482@2x.png" alt="Frame 2482" />
                <Frame2486
                  seuPerfil="Seu perfil"
                  text={`${userData.userType === 'Professor' ? 'Professor Independente' : 'Gestor Escolar'}`}
                />
              </HStack>
              <HStack className="concluido-1-cadastro-step-3" mt={3} ml={{ md: '4px', base: 2 }}>
                <Image width={'42px'} height={'42px'} src="/img/frame-2482-1@2x.png" alt="Frame 2482" />
                <Frame2486 seuPerfil="Sua conta" text={`${userData.email}`} className="frame-2486-1-cadastro-step-3" />
              </HStack>
              <Box className="step-01-cadastro" mt={3}>
                <SelectedStep number={3} />
                <Text className="criar-conta-cadastro publicsans-normal-nevada-24px" fontSize={{ md: '24px', base: '17px' }}>
                  Informações da instituição
                </Text>
              </Box>
              <Box paddingRight={'12px'} marginLeft={{ base: '0px', md: '60px' }} className="steps-cadastro">
                <FormControl isInvalid={!!errors.nomeInstituicao} mt={4}>
                  <FormLabel className={`${errors.nomeInstituicao ? 'error-cadastro' : ''} publicsans-bold-nevada-16px`}>
                    Nome da instituição escolar
                  </FormLabel>
                  <Input
                    type="text"
                    h={'48px'}
                    w={'100%'}
                    backgroundColor={'white'}
                    borderColor={'#d7e3fb'}
                    placeholder="Ex. Colégio Progresso"
                    {...register('nomeInstituicao')}
                  />
                  <FormErrorMessage>{errors.nomeInstituicao?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.documentNumber}>
                  <FormLabel className={`${errors.documentNumber ? 'error-cadastro' : ''} publicsans-bold-nevada-16px`}>
                    CNPJ
                  </FormLabel>
                  <Input
                    type="text"
                    h={'48px'}
                    w={'100%'}
                    backgroundColor={'white'}
                    borderColor={'#d7e3fb'}
                    placeholder="00.000.000/0000-00"
                    {...register('documentNumber')}
                    as={InputMask}
                    mask={inputCNPJMask}
                  />
                  <FormErrorMessage>{errors.documentNumber?.message}</FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel className="publicsans-bold-nevada-16px">Adicione uma instituição automaticamente</FormLabel>
                  <RadioGroup defaultValue="0">
                    <HStack spacing="24px">
                      <Radio value="0" borderColor="gray.300" {...register('institution')}>
                        Enem
                      </Radio>
                      <Radio value="10" borderColor="gray.300" {...register('institution')}>
                        ITA
                      </Radio>
                      <Radio value="99" borderColor="gray.300" {...register('institution')}>
                        Nenhuma
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>
              </Box>
              <HStack
                width="100%"
                marginTop={{ md: '50px', base: '16px' }}
                flexDir={{ md: 'row', base: 'column-reverse' }}
                paddingRight={'12px'}>
                <Button onClick={handleReturn} isDisabled={isLoading} width="100%" height="48px" variant="outline">
                  Voltar
                </Button>
                <Button type="submit" isLoading={isLoading} width="100%" height="48px" variant={'primary'}>
                  Finalizar
                </Button>
              </HStack>
            </VStack>
          </Box>
        </form>
      </Box>
    </HStack>
  )
}

function Frame2486(props) {
  const { seuPerfil, text, className } = props

  return (
    <div className={`frame-2486-cadastro-step-3 ${className || ''}`}>
      <div className="seu-perfil-cadastro-step-3 publicsans-normal-nevada-20px">{seuPerfil}</div>
      <div className="text-cadastro-step-3 publicsans-normal-nevada-16px">{text}</div>
    </div>
  )
}

export default SignUpStep3
