/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */
import type { KeyValueViewModel } from './keyValueViewModel';

export interface BasicThemeViewModel {
  amountActivities?: number;
  /** @nullable */
  base64Image?: string | null;
  createdAt?: string;
  /** @nullable */
  description?: string | null;
  id?: string;
  school?: KeyValueViewModel;
  /** @nullable */
  subject?: string | null;
}
