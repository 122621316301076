import { RoleTypes } from '../utils/RoleTypes'
import { httpRequestHelper } from './ApiMiddleware'
import { BACKEND_API } from './Constants'
import { getStudentDetails } from './studentApi/studentApi'

export const DoLogin = async (credentials, setLoggedUserData) => {
  const response = await httpRequestHelper.post(`${BACKEND_API}/v1/account/login`, {
    email: credentials.email,
    password: credentials.password,
    language: 'pt-br',
  })

  if (!response.data.message.userToken) {
    return response
  }

  const userId = response.data.message.userToken.id

  const claims = response.data.message.userToken.claims

  setLoggedUserData({ user_token: response.data.message.accessToken })

  for (const e of claims) {
    if (e.type === 'email') {
      setLoggedUserData({ user_email: e.value })
    } else if (e.type === 'role') {
      setLoggedUserData({ user_role: e.value })

      if (e.value === RoleTypes.INDEPENDENT_TEACHER) {
        setLoggedUserData({ schoolId: userId })
      } else if (e.value === RoleTypes.TEACHER) {
        const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/teacher`)
        setLoggedUserData({ schoolId: response.data.message.schools[0].id })
      } else if (e.value === RoleTypes.STUDENT) {
        const studentResult = await getStudentDetails()
        setLoggedUserData({ schoolId: studentResult.schools[0]?.id })
      }
    } else if (e.type === 'language') {
      setLoggedUserData({ user_language: e.value })
    } else if (e.type === 'name') {
      setLoggedUserData({ user_name: e.value })
    } else if (e.type === 'sub') {
      setLoggedUserData({ user_id: e.value })
    } else if (e.type === 'iat') {
      setLoggedUserData({ request_date: e.value })
    }
  }

  return response
}

export const validateToken = async () => {
  const token = localStorage.getItem('user_token')

  if (!token) {
    return false
  }

  const currentDate = new Date()
  const requestDate = new Date(localStorage.getItem('request_date') * 1000)

  const result = await httpRequestHelper.get(`${BACKEND_API}/v1/account/validate-token?token=${token}`)

  return result.data.success && currentDate < requestDate.setDate(requestDate.getDate() + 1)
}

export const logoff = () => {
  localStorage.clear()
  window.location.href = '/login'
}

export const changePasswordApi = async (viewModel) => {
  return await httpRequestHelper.post(`${BACKEND_API}/v1/account/change-password`, {
    email: viewModel.email,
    password: viewModel.oldPassword,
    newPassword: viewModel.newPassword,
    confirmPassword: viewModel.newPassword,
  })
}

export const requestPasswordChangeApi = async (email) => {
  return await httpRequestHelper.post(`${BACKEND_API}/v1/account/forgot-password`, {
    email,
    language: 'pt-br',
  })
}
