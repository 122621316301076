/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */

export interface ProblemDetails {
  /** @nullable */
  detail?: string | null;
  /** @nullable */
  instance?: string | null;
  /** @nullable */
  status?: number | null;
  /** @nullable */
  title?: string | null;
  /** @nullable */
  type?: string | null;
  [key: string]: unknown;
}
