/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  ActivityViewModelResultViewModel,
  AdmUpdateStudentViewModel,
  BasicStudentViewModel,
  NewStudentViewModel,
  ProblemDetails,
  StudentListViewModelListResultViewModel,
  StudentNameViewModelResultViewModel,
  StudentViewModelListResultViewModel,
  StudentViewModelResultViewModel,
  UpdateStudentViewModel,
  UserGroupsViewModel
} from './types'




/**
 * @summary Creates a new student and attaches it to groups.
If Student already exists, will be added to given new groups
 */
export const postApiV1Student = (
    newStudentViewModel: NewStudentViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/student`,
      newStudentViewModel,options
    );
  }



export const getPostApiV1StudentMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Student>>, TError,{data: NewStudentViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1Student>>, TError,{data: NewStudentViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1Student>>, {data: NewStudentViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1Student(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1StudentMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1Student>>>
    export type PostApiV1StudentMutationBody = NewStudentViewModel
    export type PostApiV1StudentMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Creates a new student and attaches it to groups.
If Student already exists, will be added to given new groups
 */
export const usePostApiV1Student = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Student>>, TError,{data: NewStudentViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1Student>>,
        TError,
        {data: NewStudentViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1StudentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Returns all details from a logged student.
 */
export const getApiV1Student = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<StudentViewModelResultViewModel>> => {
    
    return axios.get(
      `/api/v1/student`,options
    );
  }


export const getGetApiV1StudentQueryKey = () => {
    return [`/api/v1/student`] as const;
    }

    
export const getGetApiV1StudentQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1Student>>, TError = AxiosError<ProblemDetails | void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1Student>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1StudentQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Student>>> = ({ signal }) => getApiV1Student({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1Student>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1StudentQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Student>>>
export type GetApiV1StudentQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1Student<TData = Awaited<ReturnType<typeof getApiV1Student>>, TError = AxiosError<ProblemDetails | void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1Student>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1Student>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1Student<TData = Awaited<ReturnType<typeof getApiV1Student>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1Student>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1Student>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1Student<TData = Awaited<ReturnType<typeof getApiV1Student>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1Student>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns all details from a logged student.
 */

export function useGetApiV1Student<TData = Awaited<ReturnType<typeof getApiV1Student>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1Student>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1StudentQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Updates very basic information of logged student
 */
export const putApiV1Student = (
    updateStudentViewModel: UpdateStudentViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/student`,
      updateStudentViewModel,options
    );
  }



export const getPutApiV1StudentMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1Student>>, TError,{data: UpdateStudentViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1Student>>, TError,{data: UpdateStudentViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1Student>>, {data: UpdateStudentViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1Student(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1StudentMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1Student>>>
    export type PutApiV1StudentMutationBody = UpdateStudentViewModel
    export type PutApiV1StudentMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates very basic information of logged student
 */
export const usePutApiV1Student = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1Student>>, TError,{data: UpdateStudentViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1Student>>,
        TError,
        {data: UpdateStudentViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1StudentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary List all students from a certain school. It also validates if the logged user has access to this school
 */
export const getApiV1StudentSchoolSchoolId = (
    schoolId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<StudentViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/student/school/${schoolId}`,options
    );
  }


export const getGetApiV1StudentSchoolSchoolIdQueryKey = (schoolId: string,) => {
    return [`/api/v1/student/school/${schoolId}`] as const;
    }

    
export const getGetApiV1StudentSchoolSchoolIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1StudentSchoolSchoolIdQueryKey(schoolId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>> = ({ signal }) => getApiV1StudentSchoolSchoolId(schoolId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(schoolId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1StudentSchoolSchoolIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>>
export type GetApiV1StudentSchoolSchoolIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1StudentSchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1StudentSchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1StudentSchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary List all students from a certain school. It also validates if the logged user has access to this school
 */

export function useGetApiV1StudentSchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1StudentSchoolSchoolIdQueryOptions(schoolId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary List all students from a given Adm
 */
export const getApiV1StudentListFromAdmAdmId = (
    admId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BasicStudentViewModel[]>> => {
    
    return axios.get(
      `/api/v1/student/list-from-adm/${admId}`,options
    );
  }


export const getGetApiV1StudentListFromAdmAdmIdQueryKey = (admId: string,) => {
    return [`/api/v1/student/list-from-adm/${admId}`] as const;
    }

    
export const getGetApiV1StudentListFromAdmAdmIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>, TError = AxiosError<ProblemDetails | void>>(admId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1StudentListFromAdmAdmIdQueryKey(admId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>> = ({ signal }) => getApiV1StudentListFromAdmAdmId(admId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(admId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1StudentListFromAdmAdmIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>>
export type GetApiV1StudentListFromAdmAdmIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1StudentListFromAdmAdmId<TData = Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>, TError = AxiosError<ProblemDetails | void>>(
 admId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1StudentListFromAdmAdmId<TData = Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>, TError = AxiosError<ProblemDetails | void>>(
 admId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1StudentListFromAdmAdmId<TData = Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>, TError = AxiosError<ProblemDetails | void>>(
 admId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary List all students from a given Adm
 */

export function useGetApiV1StudentListFromAdmAdmId<TData = Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>, TError = AxiosError<ProblemDetails | void>>(
 admId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentListFromAdmAdmId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1StudentListFromAdmAdmIdQueryOptions(admId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Gets all info about a given Activity
 */
export const getApiV1StudentActivityActivityId = (
    activityId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ActivityViewModelResultViewModel>> => {
    
    return axios.get(
      `/api/v1/student/activity/${activityId}`,options
    );
  }


export const getGetApiV1StudentActivityActivityIdQueryKey = (activityId: string,) => {
    return [`/api/v1/student/activity/${activityId}`] as const;
    }

    
export const getGetApiV1StudentActivityActivityIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>, TError = AxiosError<ProblemDetails | void>>(activityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1StudentActivityActivityIdQueryKey(activityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>> = ({ signal }) => getApiV1StudentActivityActivityId(activityId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(activityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1StudentActivityActivityIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>>
export type GetApiV1StudentActivityActivityIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1StudentActivityActivityId<TData = Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>, TError = AxiosError<ProblemDetails | void>>(
 activityId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1StudentActivityActivityId<TData = Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>, TError = AxiosError<ProblemDetails | void>>(
 activityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1StudentActivityActivityId<TData = Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>, TError = AxiosError<ProblemDetails | void>>(
 activityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Gets all info about a given Activity
 */

export function useGetApiV1StudentActivityActivityId<TData = Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>, TError = AxiosError<ProblemDetails | void>>(
 activityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentActivityActivityId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1StudentActivityActivityIdQueryOptions(activityId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary List all students from a certain school group. It also validates if the logged user has access to this school
 */
export const getApiV1StudentSchoolSchoolIdGroupGroupId = (
    schoolId: string,
    groupId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<StudentViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/student/school/${schoolId}/group/${groupId}`,options
    );
  }


export const getGetApiV1StudentSchoolSchoolIdGroupGroupIdQueryKey = (schoolId: string,
    groupId: string,) => {
    return [`/api/v1/student/school/${schoolId}/group/${groupId}`] as const;
    }

    
export const getGetApiV1StudentSchoolSchoolIdGroupGroupIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(schoolId: string,
    groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1StudentSchoolSchoolIdGroupGroupIdQueryKey(schoolId,groupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>> = ({ signal }) => getApiV1StudentSchoolSchoolIdGroupGroupId(schoolId,groupId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(schoolId && groupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1StudentSchoolSchoolIdGroupGroupIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>>
export type GetApiV1StudentSchoolSchoolIdGroupGroupIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1StudentSchoolSchoolIdGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    groupId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1StudentSchoolSchoolIdGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1StudentSchoolSchoolIdGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary List all students from a certain school group. It also validates if the logged user has access to this school
 */

export function useGetApiV1StudentSchoolSchoolIdGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentSchoolSchoolIdGroupGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1StudentSchoolSchoolIdGroupGroupIdQueryOptions(schoolId,groupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Returns all details from a specific student.
Note that this endpoint only shows schools attached to this student that logged user is the ADM
 */
export const getApiV1StudentStudentId = (
    studentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<StudentViewModelResultViewModel>> => {
    
    return axios.get(
      `/api/v1/student/${studentId}`,options
    );
  }


export const getGetApiV1StudentStudentIdQueryKey = (studentId: string,) => {
    return [`/api/v1/student/${studentId}`] as const;
    }

    
export const getGetApiV1StudentStudentIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1StudentStudentId>>, TError = AxiosError<ProblemDetails | void>>(studentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1StudentStudentIdQueryKey(studentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1StudentStudentId>>> = ({ signal }) => getApiV1StudentStudentId(studentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(studentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1StudentStudentIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1StudentStudentId>>>
export type GetApiV1StudentStudentIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1StudentStudentId<TData = Awaited<ReturnType<typeof getApiV1StudentStudentId>>, TError = AxiosError<ProblemDetails | void>>(
 studentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1StudentStudentId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1StudentStudentId<TData = Awaited<ReturnType<typeof getApiV1StudentStudentId>>, TError = AxiosError<ProblemDetails | void>>(
 studentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1StudentStudentId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1StudentStudentId<TData = Awaited<ReturnType<typeof getApiV1StudentStudentId>>, TError = AxiosError<ProblemDetails | void>>(
 studentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns all details from a specific student.
Note that this endpoint only shows schools attached to this student that logged user is the ADM
 */

export function useGetApiV1StudentStudentId<TData = Awaited<ReturnType<typeof getApiV1StudentStudentId>>, TError = AxiosError<ProblemDetails | void>>(
 studentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1StudentStudentIdQueryOptions(studentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Removes a given student
 */
export const deleteApiV1StudentStudentId = (
    studentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/v1/student/${studentId}`,options
    );
  }



export const getDeleteApiV1StudentStudentIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1StudentStudentId>>, TError,{studentId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1StudentStudentId>>, TError,{studentId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1StudentStudentId>>, {studentId: string}> = (props) => {
          const {studentId} = props ?? {};

          return  deleteApiV1StudentStudentId(studentId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1StudentStudentIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1StudentStudentId>>>
    
    export type DeleteApiV1StudentStudentIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes a given student
 */
export const useDeleteApiV1StudentStudentId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1StudentStudentId>>, TError,{studentId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV1StudentStudentId>>,
        TError,
        {studentId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1StudentStudentIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates very basic information of student (ADM only)
 */
export const putApiV1StudentStudentId = (
    studentId: string,
    admUpdateStudentViewModel: AdmUpdateStudentViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/student/${studentId}`,
      admUpdateStudentViewModel,options
    );
  }



export const getPutApiV1StudentStudentIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1StudentStudentId>>, TError,{studentId: string;data: AdmUpdateStudentViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1StudentStudentId>>, TError,{studentId: string;data: AdmUpdateStudentViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1StudentStudentId>>, {studentId: string;data: AdmUpdateStudentViewModel}> = (props) => {
          const {studentId,data} = props ?? {};

          return  putApiV1StudentStudentId(studentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1StudentStudentIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1StudentStudentId>>>
    export type PutApiV1StudentStudentIdMutationBody = AdmUpdateStudentViewModel
    export type PutApiV1StudentStudentIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates very basic information of student (ADM only)
 */
export const usePutApiV1StudentStudentId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1StudentStudentId>>, TError,{studentId: string;data: AdmUpdateStudentViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1StudentStudentId>>,
        TError,
        {studentId: string;data: AdmUpdateStudentViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1StudentStudentIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Returns details of all students that belongs to the adm schools
 */
export const getApiV1StudentStudentAdm = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<StudentListViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/student/student-adm`,options
    );
  }


export const getGetApiV1StudentStudentAdmQueryKey = () => {
    return [`/api/v1/student/student-adm`] as const;
    }

    
export const getGetApiV1StudentStudentAdmQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>, TError = AxiosError<ProblemDetails | void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1StudentStudentAdmQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>> = ({ signal }) => getApiV1StudentStudentAdm({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1StudentStudentAdmQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>>
export type GetApiV1StudentStudentAdmQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1StudentStudentAdm<TData = Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>, TError = AxiosError<ProblemDetails | void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1StudentStudentAdm<TData = Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1StudentStudentAdm<TData = Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns details of all students that belongs to the adm schools
 */

export function useGetApiV1StudentStudentAdm<TData = Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentAdm>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1StudentStudentAdmQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Returns the student name based on the email, if exists and student is not deleted.
 */
export const getApiV1StudentStudentCheckStudentEmail = (
    studentEmail: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<StudentNameViewModelResultViewModel>> => {
    
    return axios.get(
      `/api/v1/student/student-check/${studentEmail}`,options
    );
  }


export const getGetApiV1StudentStudentCheckStudentEmailQueryKey = (studentEmail: string,) => {
    return [`/api/v1/student/student-check/${studentEmail}`] as const;
    }

    
export const getGetApiV1StudentStudentCheckStudentEmailQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>, TError = AxiosError<ProblemDetails | void>>(studentEmail: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1StudentStudentCheckStudentEmailQueryKey(studentEmail);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>> = ({ signal }) => getApiV1StudentStudentCheckStudentEmail(studentEmail, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(studentEmail), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1StudentStudentCheckStudentEmailQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>>
export type GetApiV1StudentStudentCheckStudentEmailQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1StudentStudentCheckStudentEmail<TData = Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>, TError = AxiosError<ProblemDetails | void>>(
 studentEmail: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1StudentStudentCheckStudentEmail<TData = Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>, TError = AxiosError<ProblemDetails | void>>(
 studentEmail: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1StudentStudentCheckStudentEmail<TData = Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>, TError = AxiosError<ProblemDetails | void>>(
 studentEmail: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns the student name based on the email, if exists and student is not deleted.
 */

export function useGetApiV1StudentStudentCheckStudentEmail<TData = Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>, TError = AxiosError<ProblemDetails | void>>(
 studentEmail: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1StudentStudentCheckStudentEmail>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1StudentStudentCheckStudentEmailQueryOptions(studentEmail,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Attaches a student into many groups
 */
export const postApiV1StudentAddToGroups = (
    userGroupsViewModel: UserGroupsViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/student/add-to-groups`,
      userGroupsViewModel,options
    );
  }



export const getPostApiV1StudentAddToGroupsMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1StudentAddToGroups>>, TError,{data: UserGroupsViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1StudentAddToGroups>>, TError,{data: UserGroupsViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1StudentAddToGroups>>, {data: UserGroupsViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1StudentAddToGroups(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1StudentAddToGroupsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1StudentAddToGroups>>>
    export type PostApiV1StudentAddToGroupsMutationBody = UserGroupsViewModel
    export type PostApiV1StudentAddToGroupsMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Attaches a student into many groups
 */
export const usePostApiV1StudentAddToGroups = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1StudentAddToGroups>>, TError,{data: UserGroupsViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1StudentAddToGroups>>,
        TError,
        {data: UserGroupsViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1StudentAddToGroupsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Removes a student from many groups
 */
export const deleteApiV1StudentRemoveFromGroups = (
    userGroupsViewModel: UserGroupsViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/v1/student/remove-from-groups`,{data:
      userGroupsViewModel, ...options}
    );
  }



export const getDeleteApiV1StudentRemoveFromGroupsMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1StudentRemoveFromGroups>>, TError,{data: UserGroupsViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1StudentRemoveFromGroups>>, TError,{data: UserGroupsViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1StudentRemoveFromGroups>>, {data: UserGroupsViewModel}> = (props) => {
          const {data} = props ?? {};

          return  deleteApiV1StudentRemoveFromGroups(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1StudentRemoveFromGroupsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1StudentRemoveFromGroups>>>
    export type DeleteApiV1StudentRemoveFromGroupsMutationBody = UserGroupsViewModel
    export type DeleteApiV1StudentRemoveFromGroupsMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes a student from many groups
 */
export const useDeleteApiV1StudentRemoveFromGroups = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1StudentRemoveFromGroups>>, TError,{data: UserGroupsViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV1StudentRemoveFromGroups>>,
        TError,
        {data: UserGroupsViewModel},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1StudentRemoveFromGroupsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    