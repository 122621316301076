/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */
import type { KeyValueViewModel } from './keyValueViewModel';

export interface ActivityWithTotalsViewModel {
  amountEssaysClosed?: number;
  amountEssaysDraft?: number;
  amountEssaysReviewed?: number;
  amountEssaysSubmitted?: number;
  createdAt?: string;
  deadline?: string;
  /** @nullable */
  description?: string | null;
  id?: string;
  institution?: KeyValueViewModel;
  school?: KeyValueViewModel;
  /** @nullable */
  subject?: string | null;
  teacher?: KeyValueViewModel;
  theme?: KeyValueViewModel;
  validFrom?: string;
}
