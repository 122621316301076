/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */
import type { StudentEssayDetailsViewModel } from './studentEssayDetailsViewModel';
import type { SchoolStudentViewModel } from './schoolStudentViewModel';

export interface StudentViewModel {
  blocked?: boolean;
  deleted?: boolean;
  /** @nullable */
  email?: string | null;
  /** @nullable */
  essays?: StudentEssayDetailsViewModel[] | null;
  id?: string;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  phoneNumber?: string | null;
  /** @nullable */
  schools?: SchoolStudentViewModel[] | null;
}
