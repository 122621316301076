/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */
import type { AssistantUsageReaction } from './assistantUsageReaction';

export interface AssistantReactionsViewModel {
  /** @nullable */
  assistantType?: string | null;
  /** @nullable */
  commentReaction?: string | null;
  date?: string;
  id?: string;
  reaction?: AssistantUsageReaction;
}
