/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */

export interface LoginUserViewModel {
  /** @minLength 1 */
  email: string;
  /** @nullable */
  language?: string | null;
  /**
   * @minLength 6
   * @maxLength 20
   */
  password: string;
}
