/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BlockUserViewModel,
  ProblemDetails,
  SchoolViewModelResultViewModel,
  UserViewModelListResultViewModel
} from './types'




/**
 * @summary Lists all users that administrates a school regardless of their deleted status
 */
export const getApiV1OwnerListUsersRoleSkipTake = (
    role: number,
    skip: number,
    take: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/owner/list-users/${role}/${skip}/${take}`,options
    );
  }


export const getGetApiV1OwnerListUsersRoleSkipTakeQueryKey = (role: number,
    skip: number,
    take: number,) => {
    return [`/api/v1/owner/list-users/${role}/${skip}/${take}`] as const;
    }

    
export const getGetApiV1OwnerListUsersRoleSkipTakeQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>, TError = AxiosError<ProblemDetails | void>>(role: number,
    skip: number,
    take: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1OwnerListUsersRoleSkipTakeQueryKey(role,skip,take);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>> = ({ signal }) => getApiV1OwnerListUsersRoleSkipTake(role,skip,take, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(role && skip && take), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1OwnerListUsersRoleSkipTakeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>>
export type GetApiV1OwnerListUsersRoleSkipTakeQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1OwnerListUsersRoleSkipTake<TData = Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>, TError = AxiosError<ProblemDetails | void>>(
 role: number,
    skip: number,
    take: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1OwnerListUsersRoleSkipTake<TData = Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>, TError = AxiosError<ProblemDetails | void>>(
 role: number,
    skip: number,
    take: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1OwnerListUsersRoleSkipTake<TData = Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>, TError = AxiosError<ProblemDetails | void>>(
 role: number,
    skip: number,
    take: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Lists all users that administrates a school regardless of their deleted status
 */

export function useGetApiV1OwnerListUsersRoleSkipTake<TData = Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>, TError = AxiosError<ProblemDetails | void>>(
 role: number,
    skip: number,
    take: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1OwnerListUsersRoleSkipTake>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1OwnerListUsersRoleSkipTakeQueryOptions(role,skip,take,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Lists all schools that belongs to logged user
 */
export const getApiV1OwnerListSchoolsFromUserUserId = (
    userId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SchoolViewModelResultViewModel>> => {
    
    return axios.get(
      `/api/v1/owner/list-schools-from-user/${userId}`,options
    );
  }


export const getGetApiV1OwnerListSchoolsFromUserUserIdQueryKey = (userId: string,) => {
    return [`/api/v1/owner/list-schools-from-user/${userId}`] as const;
    }

    
export const getGetApiV1OwnerListSchoolsFromUserUserIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>, TError = AxiosError<ProblemDetails | void>>(userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1OwnerListSchoolsFromUserUserIdQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>> = ({ signal }) => getApiV1OwnerListSchoolsFromUserUserId(userId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1OwnerListSchoolsFromUserUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>>
export type GetApiV1OwnerListSchoolsFromUserUserIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1OwnerListSchoolsFromUserUserId<TData = Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>, TError = AxiosError<ProblemDetails | void>>(
 userId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1OwnerListSchoolsFromUserUserId<TData = Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>, TError = AxiosError<ProblemDetails | void>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1OwnerListSchoolsFromUserUserId<TData = Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>, TError = AxiosError<ProblemDetails | void>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Lists all schools that belongs to logged user
 */

export function useGetApiV1OwnerListSchoolsFromUserUserId<TData = Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>, TError = AxiosError<ProblemDetails | void>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1OwnerListSchoolsFromUserUserId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1OwnerListSchoolsFromUserUserIdQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Sets the "Deleted" flag of certain user to true, if it exists
 */
export const deleteApiV1OwnerDeleteUserUserId = (
    userId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/v1/owner/delete-user/${userId}`,options
    );
  }



export const getDeleteApiV1OwnerDeleteUserUserIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1OwnerDeleteUserUserId>>, TError,{userId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1OwnerDeleteUserUserId>>, TError,{userId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1OwnerDeleteUserUserId>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  deleteApiV1OwnerDeleteUserUserId(userId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1OwnerDeleteUserUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1OwnerDeleteUserUserId>>>
    
    export type DeleteApiV1OwnerDeleteUserUserIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Sets the "Deleted" flag of certain user to true, if it exists
 */
export const useDeleteApiV1OwnerDeleteUserUserId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1OwnerDeleteUserUserId>>, TError,{userId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV1OwnerDeleteUserUserId>>,
        TError,
        {userId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1OwnerDeleteUserUserIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Removes permanently an user
 */
export const deleteApiV1OwnerRemoveUserUserId = (
    userId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/v1/owner/remove-user/${userId}`,options
    );
  }



export const getDeleteApiV1OwnerRemoveUserUserIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1OwnerRemoveUserUserId>>, TError,{userId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1OwnerRemoveUserUserId>>, TError,{userId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1OwnerRemoveUserUserId>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  deleteApiV1OwnerRemoveUserUserId(userId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1OwnerRemoveUserUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1OwnerRemoveUserUserId>>>
    
    export type DeleteApiV1OwnerRemoveUserUserIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes permanently an user
 */
export const useDeleteApiV1OwnerRemoveUserUserId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1OwnerRemoveUserUserId>>, TError,{userId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV1OwnerRemoveUserUserId>>,
        TError,
        {userId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1OwnerRemoveUserUserIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Removes a theme permanently
 */
export const deleteApiV1OwnerRemoveThemeThemeId = (
    themeId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/v1/owner/remove-theme/${themeId}`,options
    );
  }



export const getDeleteApiV1OwnerRemoveThemeThemeIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1OwnerRemoveThemeThemeId>>, TError,{themeId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1OwnerRemoveThemeThemeId>>, TError,{themeId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1OwnerRemoveThemeThemeId>>, {themeId: string}> = (props) => {
          const {themeId} = props ?? {};

          return  deleteApiV1OwnerRemoveThemeThemeId(themeId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1OwnerRemoveThemeThemeIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1OwnerRemoveThemeThemeId>>>
    
    export type DeleteApiV1OwnerRemoveThemeThemeIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes a theme permanently
 */
export const useDeleteApiV1OwnerRemoveThemeThemeId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1OwnerRemoveThemeThemeId>>, TError,{themeId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV1OwnerRemoveThemeThemeId>>,
        TError,
        {themeId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1OwnerRemoveThemeThemeIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Sets the "Deleted" flag of certain user to false, if it exists
 */
export const putApiV1OwnerRecoverUserUserId = (
    userId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/owner/recover-user/${userId}`,undefined,options
    );
  }



export const getPutApiV1OwnerRecoverUserUserIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1OwnerRecoverUserUserId>>, TError,{userId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1OwnerRecoverUserUserId>>, TError,{userId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1OwnerRecoverUserUserId>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  putApiV1OwnerRecoverUserUserId(userId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1OwnerRecoverUserUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1OwnerRecoverUserUserId>>>
    
    export type PutApiV1OwnerRecoverUserUserIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Sets the "Deleted" flag of certain user to false, if it exists
 */
export const usePutApiV1OwnerRecoverUserUserId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1OwnerRecoverUserUserId>>, TError,{userId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1OwnerRecoverUserUserId>>,
        TError,
        {userId: string},
        TContext
      > => {

      const mutationOptions = getPutApiV1OwnerRecoverUserUserIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Blocks or unblock an user from the system
 */
export const putApiV1OwnerBlockUser = (
    blockUserViewModel: BlockUserViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/owner/block-user`,
      blockUserViewModel,options
    );
  }



export const getPutApiV1OwnerBlockUserMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1OwnerBlockUser>>, TError,{data: BlockUserViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1OwnerBlockUser>>, TError,{data: BlockUserViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1OwnerBlockUser>>, {data: BlockUserViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1OwnerBlockUser(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1OwnerBlockUserMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1OwnerBlockUser>>>
    export type PutApiV1OwnerBlockUserMutationBody = BlockUserViewModel
    export type PutApiV1OwnerBlockUserMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Blocks or unblock an user from the system
 */
export const usePutApiV1OwnerBlockUser = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1OwnerBlockUser>>, TError,{data: BlockUserViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1OwnerBlockUser>>,
        TError,
        {data: BlockUserViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1OwnerBlockUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    