import { format, parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import '../GroupDetails.css'
import { Box, Container, HStack, Image, Text, VStack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { getActivityInformation, getGroupInformation } from '../../../apis/groupApi/GroupApi'
import SearchComponent from '../../../components/SearchComponent'
import SharedTablePagination from '../../../components/SharedTablePagination'
import useLoggedUserStore from '../../../store/useLoggedUserStore'
import sanitizedDate from '../../../utils/DateSatinizerInput'
import { essayStatus, returnEssayStatusClassName } from '../../../utils/essayStatus'
import { useAddToHistory } from '../../../utils/useNavigationHooks'

function GroupDetailsStudent() {
  const { turmaID } = useParams()

  const { loggedUser } = useLoggedUserStore()

  const navigate = useNavigate()

  const [turmasData, setTurmasData] = useState({})
  const [activityData, setActivityData] = useState([])
  const [activity, setActivity] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedTab, setSelectedTab] = useState('atividades vinculadas')

  const [filterTextActivity, setFilterTextActivity] = useState('')

  async function fetchInitialData() {
    const [activityInformation, groupInformation] = await Promise.all([
      getActivityInformation(turmaID),
      getGroupInformation(turmaID, loggedUser.schoolId),
    ])
    return { activityInformation, groupInformation }
  }

  useAddToHistory()

  useEffect(() => {
    async function fetchData() {
      fetchInitialData().then(({ activityInformation, groupInformation }) => {
        setActivityData(activityInformation)
        setTurmasData(groupInformation)

        setLoading(false)
      })
    }

    fetchData()
  }, [])

  useEffect(() => {
    handleRender()
  }, [loading])

  function handleRender() {
    geradorStatus()
    geradorTema()
    geradorSetas()
    geradorAluno()
    geradorDataDeEnvio()
  }

  useEffect(() => {
    setActivity(
      activityData.filter((item) => {
        const themeMatch = item.theme.name.toLowerCase().includes(filterTextActivity.toLowerCase())

        const activityMatch = item.subject.toLowerCase().includes(filterTextActivity.toLowerCase())

        const dateMatch = item.deadline && sanitizedDate(item.deadline).includes(filterTextActivity)

        return themeMatch || activityMatch || dateMatch
      }),
    )
  }, [filterTextActivity, activityData])

  function geradorStatus(status) {
    const backgroundColor = returnEssayStatusClassName(status)
    return (
      <div className={'atividade-txt-detalhamento-turma publicsans-normal-black-12px'}>
        <div className={backgroundColor}>{essayStatus[status]?.label}</div>
      </div>
    )
  }

  function geradorTema(tema) {
    return <AtividadeTxt21 tema={tema} />
  }

  function geradorSetas(link) {
    return (
      <Box className="atividade-txt-6-detalhamento-turma">
        <Box
          onClick={() => {
            navigate(link)
          }}>
          <Image className="ic-arrowright-detalhamento-turma" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
        </Box>
      </Box>
    )
  }

  function geradorAluno(student) {
    return <AtividadeTxt22 nomeAluno={student} />
  }

  function geradorDataDeEnvio(dataDeEnvio) {
    const dataNula = ''
    if (dataDeEnvio === undefined || dataDeEnvio === null) {
      return <AtividadeTxt20 date={dataNula} />
    }
    const dateWithoutFractions = dataDeEnvio.split('.')[0] // Remover as frações de segundos
    const parsedDate = parseISO(dateWithoutFractions)
    const formattedDate = format(parsedDate, "dd/MM/yyyy 'às' HH:mm")
    return <AtividadeTxt20 date={formattedDate} />
  }

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  return (
    <HStack gap={2} width={'100%'} maxW={'container.xl'} px={4} pb={5} flexDir={{ md: 'row', base: 'column' }}>
      <VStack width={'100%'} mt={'32px'} gap={5}>
        <Box className="tema-detalhamento-turma" height={'100%'}>
          <HStack w={'100%'} height={'100%'}>
            <VStack width={'100%'} alignSelf={'start'} alignItems={'start'}>
              <Text className="publicsans-bold-nevada-10px">TURMA</Text>
              <Text wordBreak={'break-word'} className="publicsans-normal-absolute-zero-24px">
                {turmasData.name}
              </Text>
            </VStack>
          </HStack>
        </Box>
        <HStack overflowX={'auto'} w={'100%'} height={'100%'} className="frame-2595-detalhamento-turma">
          <Box width={'100%'} inlineSize={['fit-content', 'inherit']} className="switch-detalhamento-turma">
            <Box
              className={`frame-2469-detalhamento-turma ${selectedTab === 'atividades vinculadas' ? 'tab-border' : ''}`}
              maxWidth={'250px'}
              onClick={() => changeSelectedTab('atividades vinculadas')}>
              <div
                className={`${
                  selectedTab === 'atividades vinculadas' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'
                }`}>
                Atividades vinculadas
              </div>
            </Box>
          </Box>
        </HStack>
        <Box width={'100%'}>
          {selectedTab === 'atividades vinculadas' && (
            <VStack gap={2} width={'100%'}>
              <SearchComponent
                filterText={filterTextActivity}
                handleSearchInputChange={(e) => setFilterTextActivity(e.target.value)}
              />

              {activityData.length ? (
                <SharedTablePagination
                  data={activity}
                  itemsPerPage={5}
                  columns={[
                    {
                      title: 'Atividade',
                      render: (activityData) => <div>{geradorTema(activityData.subject)}</div>,
                    },
                    {
                      title: 'Tema',
                      render: (activityData) => <div>{geradorTema(activityData.theme.name)}</div>,
                    },
                    {
                      title: 'Entrega final',
                      render: (activityData) => {
                        const generatedData = geradorDataDeEnvio(activityData.deadline)
                        return <div>{generatedData}</div>
                      },
                    },
                    {
                      title: 'Detalhes',
                      render: (activityData) => <div>{geradorSetas(`/detalhamento-atividade/${activityData.id}`)}</div>,
                    },
                  ]}
                />
              ) : (
                <Container
                  backgroundColor={'#f3f5ff'}
                  width={'100%'}
                  borderRadius={'12px'}
                  minHeight={'100px'}
                  maxWidth={'100%'}
                  textAlign={'center'}>
                  <Text marginTop={'35px'} className={'publicsans-normal-nevada-16px'}>
                    Não há atividades cadastradas nesta turma.
                  </Text>
                </Container>
              )}
            </VStack>
          )}
        </Box>
      </VStack>
      <VStack
        minWidth={'300px'}
        width={'100%'}
        mt={[5, 0]}
        mb={[5, 0]}
        pl={5}
        className="frame-2591-detalhamento-turma"
        alignSelf={{ md: 'start', base: 'center' }}>
        <TitleCta2 />
        <div className="frame-2547-detalhamento-turma">
          <div className="email-detalhamento-turma">
            <p id="editableContent" className="bem-vindoa-verif-detalhamento-turma publicsans-normal-nevada-16px">
              {loading
                ? ''
                : turmasData?.description && turmasData?.description.trim() !== ''
                  ? turmasData?.description
                  : 'Não há mensagem personalizada'}
            </p>
          </div>
        </div>
      </VStack>
    </HStack>
  )
}

export default GroupDetailsStudent

function TitleCta2() {
  return (
    <div className="title-cta-detalhamento-turma">
      <div className="title-detalhamento-turma">
        <h1 className="publicsans-normal-resolution-blue-24px">{'Mensagem personalizada'}</h1>
      </div>
    </div>
  )
}

function AtividadeTxt20(props) {
  const { date } = props

  return (
    <div className="atividade-txt-detalhamento-turma">
      <Frame25805 date={date} />
    </div>
  )
}

function Frame25805(props) {
  const { date } = props

  return (
    <div className="frame-2580-1-detalhamento-turma">
      {date !== '' ? (
        <img className="calendar-2851774-1-detalhamento-atividade" src={'/img/calendar-icon.svg'} alt="calendar-2851774" />
      ) : (
        ''
      )}
      <div className="x18112022-s-2030-detalhamento-turma valign-text-middle-detalhamento-turma publicsans-normal-black-12px">
        {date}
      </div>
    </div>
  )
}

function AtividadeTxt21(props) {
  const { tema } = props
  return (
    <div className="atividade-txt-4-detalhamento-turma">
      <div className="content-4-detalhamento-turma">
        <p className="gerao-do-sec-21-a-detalhamento-turma valign-text-middle-detalhamento-turma publicsans-normal-nevada-14px">
          {tema}
        </p>
      </div>
    </div>
  )
}

function AtividadeTxt22(props) {
  const { nomeAluno } = props

  return (
    <div className="atividade-txt-5-detalhamento-turma">
      <div className="content-5-detalhamento-turma">
        <div className="aluno-name-style valign-text-middle-detalhamento-turma publicsans-normal-black-14px">{nomeAluno}</div>
      </div>
    </div>
  )
}
