/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */
import type { Details } from './details';
import type { InstitutionViewModel } from './institutionViewModel';

export interface InstitutionViewModelResultViewModel {
  details?: Details;
  message?: InstitutionViewModel;
  success?: boolean;
}
