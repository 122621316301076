import { useEffect, useRef, useState } from 'react'
import CabecalhoTabela from '../../components/CabecalhoTabela'
import ResumoDeRedacoes from '../../components/ResumoDeRedacoes'
import './InitialPageStudent.css'
import LayoutMenu from '@/layouts/LayoutMenu'
import { useIsHistoryEmpty } from '@/utils/useNavigationHooks'
import {
  Box,
  Button,
  HStack,
  Image,
  Select,
  SimpleGrid,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { getActivitiesByStudentIdApi } from '../../apis/activitityApi/activityApi'
import { getEssaysByStudentIdApi } from '../../apis/essayApi/essayApi'
import NoContentContainer from '../../components/NoContentContainer'
import Title2 from '../../components/Title2'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import sanitizedDate from '../../utils/DateSatinizerInput'
import getMaxChildren from '../../utils/MaxCardOnScreenUtils'
import { StatusType } from '../../utils/StatusType'
import { essayStatus, returnEssayStatusClassName } from '../../utils/essayStatus'
import { HandleSubmittedDate } from '../../utils/sharedTableColumnHandlers'
import Page from '../Page'

function InitialPageStudent() {
  const { loggedUser, status } = useLoggedUserStore()
  const navigate = useNavigate()

  const [aguardandoCorrecao, setAguardandoCorrecao] = useState([])
  const [emCorrecao, setEmCorrecao] = useState([])
  const [corrigida, setCorrigida] = useState([])
  const [essays, setEssays] = useState([])
  const [activities, setActivities] = useState([])
  const [loading, setLoading] = useState(true)
  const [validEssays, setValidEssays] = useState([])
  const [daysOfEssays, setDaysOfEssays] = useState(30)

  const bodyRef = useRef(null)
  const [hStackWidth, setHStackWidth] = useState('100%')

  const fetchInitialData = async () => {
    setLoading(true)

    const [studentResult, activityResult] = await Promise.all([
      getEssaysByStudentIdApi(localStorage.getItem('user_id'), daysOfEssays),
      getActivitiesByStudentIdApi(localStorage.getItem('user_id')),
    ])

    const studentResultFiltered = studentResult.filter((result) => result.school.id === loggedUser.schoolId)
    const activityResultFiltered = activityResult.filter((result) => result.school.id === loggedUser.schoolId)

    return { studentResultFiltered, activityResultFiltered }
  }

  function handleInitialData() {
    fetchInitialData().then(({ studentResultFiltered, activityResultFiltered }) => {
      setEssays(studentResultFiltered || [])
      setActivities(activityResultFiltered?.filter((activity) => new Date(activity.deadline) >= new Date()) || [])
      setValidEssays(studentResultFiltered)

      setLoading(false)
    })
  }

  useIsHistoryEmpty()

  useEffect(() => {
    if (status === StatusType.COMPLETED) {
      handleInitialData()
    }
  }, [daysOfEssays, loggedUser.schoolId, status])

  useEffect(() => {
    handleRender()
  }, [loading])

  useEffect(() => {
    const updateWidth = () => {
      if (bodyRef.current) {
        setHStackWidth(`${bodyRef.current.offsetWidth}px`)
      }
    }

    updateWidth()
    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  function handleRender() {
    handleEssaysOverall()
  }

  function navigationHomeStudent(link) {
    navigate(link)
  }

  function mountTableRow() {
    return activities.slice(0, 4).map((activity, key) => {
      return (
        <Tr key={key}>
          <Td className={'publicsans-normal-nevada-14px'} textAlign={'center'} whiteSpace={'break-spaces'}>
            {activity.subject}
          </Td>
          <Td className={'publicsans-normal-black-12px'} textAlign={'-webkit-center'} whiteSpace={'break-spaces'}>
            <div className={'background-green'}>{activity.theme.name}</div>
          </Td>
          <Td>
            <HandleSubmittedDate dateSubmitted={activity.deadline} key={key} />
          </Td>
          <Td alignContent={'center'} textAlign={'-webkit-center'}>
            <Button backgroundColor={'white'}>
              <Box
                onClick={() => {
                  navigationHomeStudent(`/detalhamento-atividade/${activity.id}`)
                }}>
                <Image className="ic-arrowright-1" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" width={'24px'} />
              </Box>
            </Button>
          </Td>
        </Tr>
      )
    })
  }

  function calculoRedacoesStatus(statusDesejado) {
    return essays.filter((essay) => essay.status === statusDesejado).length
  }

  function handleEssaysOverall() {
    setAguardandoCorrecao(
      <ResumoDeRedacoes
        folderIcon={'/img/folder-icon.png'}
        status={essayStatus['20']}
        daysOfEssays={daysOfEssays}
        number={calculoRedacoesStatus(20)}
        linkReturnTo={'/inicio-aluno'}
      />,
    )

    setEmCorrecao(
      <ResumoDeRedacoes
        folderIcon={'/img/folder-pen-icon.png'}
        status={essayStatus['30']}
        daysOfEssays={daysOfEssays}
        number={calculoRedacoesStatus(30)}
        linkReturnTo={'/inicio-aluno'}
      />,
    )

    setCorrigida(
      <ResumoDeRedacoes
        folderIcon={'/img/folder-ok-icon.png'}
        status={essayStatus['40']}
        daysOfEssays={daysOfEssays}
        number={calculoRedacoesStatus(40)}
        linkReturnTo={'/inicio-aluno'}
      />,
    )
  }

  function EssayCard(props) {
    const { essay } = props

    const backgroundColor = returnEssayStatusClassName(essay.status)
    return (
      <VStack alignItems={'start'} gap={4}>
        <Box>
          <Text className="publicsans-bold-absolute-zero-16px">Atividade</Text>
          <Text className="publicsans-normal-nevada-16px" noOfLines={1}>
            {essay.activity.name}
          </Text>
        </Box>
        <Box>
          <Text className="publicsans-bold-absolute-zero-16px">{'Status'}</Text>
          <Text className={`publicsans-normal-nevada-16px ${backgroundColor}`}>{essayStatus[essay.status].label}</Text>
        </Box>
        <Box>
          <Text className="publicsans-bold-absolute-zero-16px">{'Data da entrega'}</Text>
          <Text className="publicsans-normal-nevada-16px">
            {essay.dateSubmitted ? sanitizedDate(essay.dateSubmitted) : 'Ainda não foi entregue'}
          </Text>
        </Box>
      </VStack>
    )
  }

  function HandleMonthlyEssay() {
    if (validEssays.length > 0) {
      const result = validEssays
        .sort((a, b) => a.status - b.status)
        .map((essay, key) => {
          return (
            <VStack
              borderRadius="lg"
              width="100%"
              align="start"
              alignContent="center"
              overflow="hidden"
              boxShadow="xl"
              key={key}>
              <VStack width="100%" bg="white" p={6} height={'100%'} alignItems={'start'} justifyContent={'space-between'}>
                <EssayCard essay={essay} />
                <Button
                  variant={'primary'}
                  w={'100%'}
                  className="publicsans-bold-white-14px"
                  mt={2}
                  onClick={() => {
                    navigationHomeStudent(`/redacao?id=${essay.id}`)
                  }}>
                  Ver Redação
                </Button>
              </VStack>
            </VStack>
          )
        })

      const maxChildren = getMaxChildren({ hStackWidth })

      const displayedChildren = result.slice(0, maxChildren)

      return (
        <SimpleGrid templateColumns="repeat(auto-fill, minmax(300px,  1fr))" spacing="30px">
          {displayedChildren}{' '}
        </SimpleGrid>
      )
    }

    return (
      <Text className={'publicsans-normal-gray-14px'} width={'100%'} textAlign={'center'}>
        Ainda não há Redações.
      </Text>
    )
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'lightblue' : null,
    }),
    control: (provided) => ({
      ...provided,
    }),
  }

  return (
    <Page authPage={true} allowedUserType={'student'}>
      <LayoutMenu isLoading={false}>
        <LayoutMenu.Content>
          <Box className="header">
            <Text fontSize={{ md: '40px', base: '30px' }} className="publicsans-normal-absolute-zero-40px">
              Olá, Aluno(a)!
            </Text>
            <Box width={'100%'} className="publicsans-normal-nevada-20px">
              <HStack flexDir={{ md: 'row', base: 'column' }} w={'100%'} alignItems={'start'} justifyContent={'space-between'}>
                <Text width={'100%'} fontSize={{ md: '20px', base: '17px' }}>
                  O que vamos corrigir hoje?
                </Text>
                <Spacer />
                <Select
                  onChange={(e) => {
                    setDaysOfEssays(Number.parseInt(e.target.value))
                  }}
                  borderColor={'#d7e3fb'}
                  backgroundColor={'white'}
                  styles={customStyles}
                  isDisabled={loading}
                  defaultValue={30}
                  maxW={'120px'}>
                  <option className={'select-days-text'} value={30}>
                    {'30 dias'}
                  </option>
                  <option className={'select-days-text'} value={90}>
                    {'90 dias'}
                  </option>
                  <option className={'select-days-text'} value={180}>
                    {'180 dias'}
                  </option>
                  <option className={'select-days-text'} value={365}>
                    {'365 dias'}
                  </option>
                </Select>
              </HStack>
            </Box>
          </Box>
          <div className="status-initial-page">
            {aguardandoCorrecao}
            {emCorrecao}
            {corrigida}
          </div>
          <Box ref={bodyRef} className="informacoes-gerais-tela-inicial">
            <CabecalhoTabela
              text={'Redações feitas neste período'}
              textLink={'Ver mais'}
              link={`/area-de-redacoes?daysOfEssays=${daysOfEssays}`}
            />
            <Box width="100%" px={4}>
              <HandleMonthlyEssay />
            </Box>
          </Box>
          <div className="atividades-em-andamento">
            <div className="title-cta-4">
              <Title2>{'Atividades em andamento'}</Title2>
              <Box justifyContent={'end'} className="cta-atividades">
                <Button
                  onClick={() => {
                    navigationHomeStudent('/area-de-atividades')
                  }}
                  variant={'ghost'}
                  cursor="pointer"
                  display="flex"
                  alignItems="center">
                  <Box className="text-27 publicsans-normal-azure-radiance-14px">{'Ver mais'}</Box>
                  <Image
                    className="setaDireita-VerTodasAtividades"
                    src={'/img/ic-arrowright-61@2x.png'}
                    alt="Seta a direita de ver todas as atividades"
                  />
                </Button>
              </Box>
            </div>
            <div className="tabela-atividades-1">
              {activities.length !== 0 ? (
                <TableContainer width={'100%'}>
                  <Table variant={'simple'}>
                    <Thead>
                      <Tr backgroundColor={'#f3f5fe'}>
                        <Th flex={1} textAlign={'center'}>
                          Atividade
                        </Th>
                        <Th flex={1} textAlign={'center'}>
                          Tema
                        </Th>
                        <Th flex={0.5} textAlign={'center'}>
                          Data final de entrega
                        </Th>
                        <Th width={'84px'} textAlign={'center'}>
                          Detalhes
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>{mountTableRow()}</Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="tabela-01-1">
                  <NoContentContainer>{'Ainda não há atividades em andamento associadas a esse aluno.'}</NoContentContainer>
                </div>
              )}
            </div>
          </div>
        </LayoutMenu.Content>
      </LayoutMenu>
    </Page>
  )
}

export default InitialPageStudent
