import './TitleLink.css'
import { Text, VStack } from '@chakra-ui/react'

function TitleLink(props) {
  const { titleContent, subTitleName, subTitleContent } = props

  return (
    <div className="title-link">
      <div className="atomsphotopicalbum" />
      <div className="frame-2587">
        <Text className="publicsans-bold-absolute-zero-16px" noOfLines={1}>
          {titleContent}
        </Text>
        <VStack w={'100%'} alignItems={'start'}>
          <Text className="publicsans-bold-nevada-10px" noOfLines={1}>
            {subTitleName}
          </Text>
          <Text className="publicsans-normal-nevada-14px" noOfLines={1}>
            {subTitleContent}
          </Text>
        </VStack>
      </div>
    </div>
  )
}

export default TitleLink
