// @ts-nocheck
import { useEffect, useState } from 'react'
import '../GroupsArea.css'
import { AreaHeader } from '@/components/AreaHeader'
import SearchComponent from '@/components/SearchComponent'
import LayoutMenu from '@/layouts/LayoutMenu'
import fontsUtils from '@/theme/foundations/FontsUtils.ts'
import { Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import NoContentContainer from '../../../components/NoContentContainer'
import SharedTablePagination from '../../../components/SharedTablePagination'
import { useGetApiV1School } from '../../../services/school'
import { shiftMapping } from '../../../utils/shiftENUM'
import Page from '../../Page'
interface Group {
  id: string
  name: string
  shift: number
  schoolId?: string
}

function AdmGroupsArea(props) {
  const { schools } = props
  const [turmas, setTurmas] = useState([])
  const [turmasData, setTurmasData] = useState([])
  const navigate = useNavigate()

  const [filterText, setFilterText] = useState('')

  const getApiV1School = useGetApiV1School()

  const fetchInitialData = () => {
    return getApiV1School.data?.data.message || []
  }

  useEffect(() => {
    if (!schools) {
      fetchInitialData().then((groupsResult) => {
        setTurmasData(groupsResult)
      })
    } else {
      const data = schools.reduce<Group[]>((acc, school) => {
        if (school.groups && school.groups.length > 0) {
          const groupsWithSchoolId = school.groups.map((group) => {
            return { ...group, schoolId: school.id }
          })
          acc.push(...groupsWithSchoolId)
        }
        return acc
      }, [])
      setTurmasData(data)
    }
  }, [])

  useEffect(() => {
    setTurmas(turmasData)
  }, [turmasData])

  function handleGroupName(groupName: string) {
    return (
      <div className="atividade-txt-area-de-turmas">
        <Text color={fontsUtils.fontGray.color} className="publicsans-normal-black-12px">
          {groupName}
        </Text>
      </div>
    )
  }

  function handleShift(turno: number) {
    return (
      <div className="atividade-txt-2-area-de-turmas">
        <div className="frame-2580-2-area-de-turmas">
          <div className="noite-area-de-turmas valign-text-middle-area-de-turmas publicsans-bold-absolute-zero-12px">
            {shiftMapping[turno]}
          </div>
        </div>
      </div>
    )
  }

  function handleDetails(group) {
    return (
      <div
        className="atividade-txt-4-area-de-turmas"
        onClick={() =>
          navigate(`/detalhamento-turma/${group.id}`, {
            state: { schoolId: group.schoolId, returnToUrl: '/inicio-adm' },
          })
        }>
        <img className="ic-arrowright-area-de-turmas" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
      </div>
    )
  }

  function handleSearchInputChange(e) {
    const inputText = e.currentTarget.value
    setFilterText(inputText)

    setTurmas(
      turmasData.filter((item) => {
        const nameMatch = item.name.toLowerCase().includes(inputText.toLowerCase())
        const shiftMatch = shiftMapping[item.shift].toLowerCase().includes(inputText.toLowerCase())
        return nameMatch || shiftMatch
      }),
    )
    setFilterText(inputText)
  }

  return (
    <LayoutMenu isLoading={getApiV1School.isPending}>
      <LayoutMenu.Content>
        <Page authPage={true} allowedUserType={'adm'} />
        <AreaHeader
          title="Área de Turmas"
          button={{
            isVisible: false,
            navigateTo: '/criar-turma',
            label: 'Criar turma',
          }}
        />
        <SearchComponent filterText={filterText} handleSearchInputChange={handleSearchInputChange} />
        {turmas.length === 0 ? (
          <NoContentContainer>Nenhuma turma foi associado ao seu perfil ainda.</NoContentContainer>
        ) : (
          <SharedTablePagination
            data={turmas}
            itemsPerPage={5}
            className={'area-de-turmas-table-pagination'}
            columns={[
              {
                title: 'Turma',
                render: (itemData) => <div>{handleGroupName(itemData.name)}</div>,
              },
              {
                title: 'Escola',
                render: (itemData) => (
                  <div className={'table-cell-container'}>
                    {<Text color={fontsUtils.fontGray.color}>{itemData.school?.name}</Text>}
                  </div>
                ),
              },
              {
                title: 'Turno',
                render: (itemData) => <div>{handleShift(itemData.shift)}</div>,
              },
              {
                title: 'Detalhes',
                render: (itemData) => <div>{handleDetails(itemData)}</div>,
              },
            ]}
          />
        )}
      </LayoutMenu.Content>
    </LayoutMenu>
  )
}

export default AdmGroupsArea
