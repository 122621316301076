import { usePostApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency } from '@/services/school.ts'
import type { NewCompetencyViewModel } from '@/services/types'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import type { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

interface CompetencyFormValues {
  name: string
  description: string
}

const validationSchema = yup.object().shape({
  name: yup.string().max(2).required(),
  description: yup.string().required(),
})

interface ModalCreateCompetencyProps {
  modalCreateCompetencyDisclosure: {
    isOpen: boolean
    onClose: () => void
  }
  itemData: { id: string }
  schoolId: string
  fetchAllInstitutions: () => Promise<void>
}

function ModalCreateCompetency({
  modalCreateCompetencyDisclosure,
  itemData,
  schoolId,
  fetchAllInstitutions,
}: ModalCreateCompetencyProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CompetencyFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      description: '',
    },
  })

  const { mutate, isPending } = usePostApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency<AxiosError>({
    mutation: {
      onSuccess: async () => {
        await fetchAllInstitutions()
        reset()
        modalCreateCompetencyDisclosure.onClose()
      },
      onError: (error) => {
        console.error('Erro ao criar competência', error)
      },
    },
  })

  const onSubmit = async (data: CompetencyFormValues) => {
    const newCompetencyData: NewCompetencyViewModel = {
      name: data.name,
      description: data.description,
    }

    try {
      mutate({
        schoolId,
        institutionId: itemData.id,
        data: newCompetencyData,
      })
      toast.success('Competência criada com sucesso!')
    } catch (error) {
      console.error('Erro ao criar competência', error)
      toast.error('Erro ao criar competência')
    }
  }

  const handleClose = () => {
    reset()
    modalCreateCompetencyDisclosure.onClose()
  }

  return (
    <Modal
      isOpen={modalCreateCompetencyDisclosure.isOpen}
      size="2xl"
      onClose={handleClose}
      isCentered
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader fontWeight="400" className="title-modal">
          Criar Competência
        </ModalHeader>
        <ModalCloseButton color="#0a41d6" marginTop="5px" onClick={handleClose} />
        <ModalBody>
          <VStack alignItems="flex-start" gap="16px">
            <FormControl isInvalid={!!errors.name}>
              <FormLabel>Nome da Competência</FormLabel>
              <Input {...register('name')} className="input-default-style" />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.description}>
              <FormLabel>Descrição da Competência</FormLabel>
              <Input {...register('description')} className="input-default-style" />
              <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter alignSelf="center" gap="32px" width="100%">
          <HStack width="100%" flexDir={{ base: 'column-reverse', md: 'row' }}>
            <Button variant="outline" height="48px" width="100%" isDisabled={isPending} onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" height="48px" width="100%" isLoading={isPending} type="submit">
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalCreateCompetency
