/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BooleanResultViewModel,
  ChangeEssayTeacherViewModel,
  CommentViewModelListResultViewModel,
  CompleteEssayViewModelResultViewModel,
  EssayGroupViewModelListResultViewModel,
  EssayStudentViewModelResultViewModel,
  EssayTeacherViewModelListResultViewModel,
  EssaysPerGroupsViewModel,
  GetApiV1EssayGroupGroupIdParams,
  GetApiV1EssayStudentStudentIdParams,
  GetApiV1EssayTeacherTeacherIdParams,
  GuidResultViewModel,
  ListOfEssayCompetenciesViewModel,
  ListOfEssayCompetenciesViewModelResultViewModel,
  NewCommentViewModel,
  NewEssayViewModel,
  ProblemDetails,
  ReturnEssayViewModel,
  UpdateCommentCompetency,
  UpdateEssayDraftViewModel
} from './types'




/**
 * @summary Creates a new essay for a given theme and returns its Id
 */
export const postApiV1Essay = (
    newEssayViewModel: NewEssayViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GuidResultViewModel>> => {
    
    return axios.post(
      `/api/v1/essay`,
      newEssayViewModel,options
    );
  }



export const getPostApiV1EssayMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Essay>>, TError,{data: NewEssayViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1Essay>>, TError,{data: NewEssayViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1Essay>>, {data: NewEssayViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1Essay(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1EssayMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1Essay>>>
    export type PostApiV1EssayMutationBody = NewEssayViewModel
    export type PostApiV1EssayMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Creates a new essay for a given theme and returns its Id
 */
export const usePostApiV1Essay = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Essay>>, TError,{data: NewEssayViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1Essay>>,
        TError,
        {data: NewEssayViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1EssayMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates Essay draft
 */
export const putApiV1Essay = (
    updateEssayDraftViewModel: UpdateEssayDraftViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BooleanResultViewModel>> => {
    
    return axios.put(
      `/api/v1/essay`,
      updateEssayDraftViewModel,options
    );
  }



export const getPutApiV1EssayMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1Essay>>, TError,{data: UpdateEssayDraftViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1Essay>>, TError,{data: UpdateEssayDraftViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1Essay>>, {data: UpdateEssayDraftViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1Essay(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1EssayMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1Essay>>>
    export type PutApiV1EssayMutationBody = UpdateEssayDraftViewModel
    export type PutApiV1EssayMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates Essay draft
 */
export const usePutApiV1Essay = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1Essay>>, TError,{data: UpdateEssayDraftViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1Essay>>,
        TError,
        {data: UpdateEssayDraftViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1EssayMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets all information from an Essay
 */
export const getApiV1EssayEssayId = (
    essayId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CompleteEssayViewModelResultViewModel>> => {
    
    return axios.get(
      `/api/v1/essay/${essayId}`,options
    );
  }


export const getGetApiV1EssayEssayIdQueryKey = (essayId: string,) => {
    return [`/api/v1/essay/${essayId}`] as const;
    }

    
export const getGetApiV1EssayEssayIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1EssayEssayId>>, TError = AxiosError<ProblemDetails | void>>(essayId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1EssayEssayIdQueryKey(essayId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1EssayEssayId>>> = ({ signal }) => getApiV1EssayEssayId(essayId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(essayId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1EssayEssayIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1EssayEssayId>>>
export type GetApiV1EssayEssayIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1EssayEssayId<TData = Awaited<ReturnType<typeof getApiV1EssayEssayId>>, TError = AxiosError<ProblemDetails | void>>(
 essayId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1EssayEssayId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1EssayEssayId<TData = Awaited<ReturnType<typeof getApiV1EssayEssayId>>, TError = AxiosError<ProblemDetails | void>>(
 essayId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1EssayEssayId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1EssayEssayId<TData = Awaited<ReturnType<typeof getApiV1EssayEssayId>>, TError = AxiosError<ProblemDetails | void>>(
 essayId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Gets all information from an Essay
 */

export function useGetApiV1EssayEssayId<TData = Awaited<ReturnType<typeof getApiV1EssayEssayId>>, TError = AxiosError<ProblemDetails | void>>(
 essayId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1EssayEssayIdQueryOptions(essayId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Removes an essay
 */
export const deleteApiV1EssayEssayId = (
    essayId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BooleanResultViewModel>> => {
    
    return axios.delete(
      `/api/v1/essay/${essayId}`,options
    );
  }



export const getDeleteApiV1EssayEssayIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1EssayEssayId>>, TError,{essayId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1EssayEssayId>>, TError,{essayId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1EssayEssayId>>, {essayId: string}> = (props) => {
          const {essayId} = props ?? {};

          return  deleteApiV1EssayEssayId(essayId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1EssayEssayIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1EssayEssayId>>>
    
    export type DeleteApiV1EssayEssayIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes an essay
 */
export const useDeleteApiV1EssayEssayId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1EssayEssayId>>, TError,{essayId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV1EssayEssayId>>,
        TError,
        {essayId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1EssayEssayIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets the most up-to-date grades of a given essay
 */
export const getApiV1EssayEssayIdRefreshGrades = (
    essayId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ListOfEssayCompetenciesViewModelResultViewModel>> => {
    
    return axios.get(
      `/api/v1/essay/${essayId}/refresh-grades`,options
    );
  }


export const getGetApiV1EssayEssayIdRefreshGradesQueryKey = (essayId: string,) => {
    return [`/api/v1/essay/${essayId}/refresh-grades`] as const;
    }

    
export const getGetApiV1EssayEssayIdRefreshGradesQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>, TError = AxiosError<ProblemDetails | void>>(essayId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1EssayEssayIdRefreshGradesQueryKey(essayId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>> = ({ signal }) => getApiV1EssayEssayIdRefreshGrades(essayId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(essayId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1EssayEssayIdRefreshGradesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>>
export type GetApiV1EssayEssayIdRefreshGradesQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1EssayEssayIdRefreshGrades<TData = Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>, TError = AxiosError<ProblemDetails | void>>(
 essayId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1EssayEssayIdRefreshGrades<TData = Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>, TError = AxiosError<ProblemDetails | void>>(
 essayId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1EssayEssayIdRefreshGrades<TData = Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>, TError = AxiosError<ProblemDetails | void>>(
 essayId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Gets the most up-to-date grades of a given essay
 */

export function useGetApiV1EssayEssayIdRefreshGrades<TData = Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>, TError = AxiosError<ProblemDetails | void>>(
 essayId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayIdRefreshGrades>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1EssayEssayIdRefreshGradesQueryOptions(essayId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Retrieves all comments from an essay
 */
export const getApiV1EssayEssayIdComments = (
    essayId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CommentViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/essay/${essayId}/comments`,options
    );
  }


export const getGetApiV1EssayEssayIdCommentsQueryKey = (essayId: string,) => {
    return [`/api/v1/essay/${essayId}/comments`] as const;
    }

    
export const getGetApiV1EssayEssayIdCommentsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>, TError = AxiosError<ProblemDetails | void>>(essayId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1EssayEssayIdCommentsQueryKey(essayId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>> = ({ signal }) => getApiV1EssayEssayIdComments(essayId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(essayId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1EssayEssayIdCommentsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>>
export type GetApiV1EssayEssayIdCommentsQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1EssayEssayIdComments<TData = Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>, TError = AxiosError<ProblemDetails | void>>(
 essayId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1EssayEssayIdComments<TData = Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>, TError = AxiosError<ProblemDetails | void>>(
 essayId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1EssayEssayIdComments<TData = Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>, TError = AxiosError<ProblemDetails | void>>(
 essayId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves all comments from an essay
 */

export function useGetApiV1EssayEssayIdComments<TData = Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>, TError = AxiosError<ProblemDetails | void>>(
 essayId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayEssayIdComments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1EssayEssayIdCommentsQueryOptions(essayId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Makes a new comment to an essay (Text or Audio)
 */
export const postApiV1EssayEssayIdComments = (
    essayId: string,
    newCommentViewModel: NewCommentViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BooleanResultViewModel>> => {
    
    return axios.post(
      `/api/v1/essay/${essayId}/comments`,
      newCommentViewModel,options
    );
  }



export const getPostApiV1EssayEssayIdCommentsMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1EssayEssayIdComments>>, TError,{essayId: string;data: NewCommentViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1EssayEssayIdComments>>, TError,{essayId: string;data: NewCommentViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1EssayEssayIdComments>>, {essayId: string;data: NewCommentViewModel}> = (props) => {
          const {essayId,data} = props ?? {};

          return  postApiV1EssayEssayIdComments(essayId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1EssayEssayIdCommentsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1EssayEssayIdComments>>>
    export type PostApiV1EssayEssayIdCommentsMutationBody = NewCommentViewModel
    export type PostApiV1EssayEssayIdCommentsMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Makes a new comment to an essay (Text or Audio)
 */
export const usePostApiV1EssayEssayIdComments = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1EssayEssayIdComments>>, TError,{essayId: string;data: NewCommentViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1EssayEssayIdComments>>,
        TError,
        {essayId: string;data: NewCommentViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1EssayEssayIdCommentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Lists all essays available to be reviewed for a given teacher that was updated on the last given amount of days.
If days not sent, no filter will be made with this parameter
 */
export const getApiV1EssayTeacherTeacherId = (
    teacherId: string,
    params?: GetApiV1EssayTeacherTeacherIdParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EssayTeacherViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/essay/teacher/${teacherId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1EssayTeacherTeacherIdQueryKey = (teacherId: string,
    params?: GetApiV1EssayTeacherTeacherIdParams,) => {
    return [`/api/v1/essay/teacher/${teacherId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV1EssayTeacherTeacherIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(teacherId: string,
    params?: GetApiV1EssayTeacherTeacherIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1EssayTeacherTeacherIdQueryKey(teacherId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>> = ({ signal }) => getApiV1EssayTeacherTeacherId(teacherId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(teacherId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1EssayTeacherTeacherIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>>
export type GetApiV1EssayTeacherTeacherIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1EssayTeacherTeacherId<TData = Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(
 teacherId: string,
    params: undefined |  GetApiV1EssayTeacherTeacherIdParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1EssayTeacherTeacherId<TData = Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(
 teacherId: string,
    params?: GetApiV1EssayTeacherTeacherIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1EssayTeacherTeacherId<TData = Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(
 teacherId: string,
    params?: GetApiV1EssayTeacherTeacherIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Lists all essays available to be reviewed for a given teacher that was updated on the last given amount of days.
If days not sent, no filter will be made with this parameter
 */

export function useGetApiV1EssayTeacherTeacherId<TData = Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(
 teacherId: string,
    params?: GetApiV1EssayTeacherTeacherIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayTeacherTeacherId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1EssayTeacherTeacherIdQueryOptions(teacherId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Returns all the active essays of a given group
 */
export const getApiV1EssayGroupGroupId = (
    groupId: string,
    params?: GetApiV1EssayGroupGroupIdParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EssayGroupViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/essay/group/${groupId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1EssayGroupGroupIdQueryKey = (groupId: string,
    params?: GetApiV1EssayGroupGroupIdParams,) => {
    return [`/api/v1/essay/group/${groupId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV1EssayGroupGroupIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(groupId: string,
    params?: GetApiV1EssayGroupGroupIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1EssayGroupGroupIdQueryKey(groupId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>> = ({ signal }) => getApiV1EssayGroupGroupId(groupId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(groupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1EssayGroupGroupIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>>
export type GetApiV1EssayGroupGroupIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1EssayGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 groupId: string,
    params: undefined |  GetApiV1EssayGroupGroupIdParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1EssayGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 groupId: string,
    params?: GetApiV1EssayGroupGroupIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1EssayGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 groupId: string,
    params?: GetApiV1EssayGroupGroupIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns all the active essays of a given group
 */

export function useGetApiV1EssayGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 groupId: string,
    params?: GetApiV1EssayGroupGroupIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayGroupGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1EssayGroupGroupIdQueryOptions(groupId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Returns all the active essays of a given group
 */
export const patchApiV1EssayGroups = (
    essaysPerGroupsViewModel: EssaysPerGroupsViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EssayGroupViewModelListResultViewModel>> => {
    
    return axios.patch(
      `/api/v1/essay/groups`,
      essaysPerGroupsViewModel,options
    );
  }



export const getPatchApiV1EssayGroupsMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV1EssayGroups>>, TError,{data: EssaysPerGroupsViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV1EssayGroups>>, TError,{data: EssaysPerGroupsViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV1EssayGroups>>, {data: EssaysPerGroupsViewModel}> = (props) => {
          const {data} = props ?? {};

          return  patchApiV1EssayGroups(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV1EssayGroupsMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV1EssayGroups>>>
    export type PatchApiV1EssayGroupsMutationBody = EssaysPerGroupsViewModel
    export type PatchApiV1EssayGroupsMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Returns all the active essays of a given group
 */
export const usePatchApiV1EssayGroups = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV1EssayGroups>>, TError,{data: EssaysPerGroupsViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV1EssayGroups>>,
        TError,
        {data: EssaysPerGroupsViewModel},
        TContext
      > => {

      const mutationOptions = getPatchApiV1EssayGroupsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Lists all essays available to the student together with the essays already assigned
 */
export const getApiV1EssayStudentStudentId = (
    studentId: string,
    params?: GetApiV1EssayStudentStudentIdParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EssayStudentViewModelResultViewModel>> => {
    
    return axios.get(
      `/api/v1/essay/student/${studentId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1EssayStudentStudentIdQueryKey = (studentId: string,
    params?: GetApiV1EssayStudentStudentIdParams,) => {
    return [`/api/v1/essay/student/${studentId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV1EssayStudentStudentIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>, TError = AxiosError<ProblemDetails | void>>(studentId: string,
    params?: GetApiV1EssayStudentStudentIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1EssayStudentStudentIdQueryKey(studentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>> = ({ signal }) => getApiV1EssayStudentStudentId(studentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(studentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1EssayStudentStudentIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>>
export type GetApiV1EssayStudentStudentIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1EssayStudentStudentId<TData = Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>, TError = AxiosError<ProblemDetails | void>>(
 studentId: string,
    params: undefined |  GetApiV1EssayStudentStudentIdParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1EssayStudentStudentId<TData = Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>, TError = AxiosError<ProblemDetails | void>>(
 studentId: string,
    params?: GetApiV1EssayStudentStudentIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1EssayStudentStudentId<TData = Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>, TError = AxiosError<ProblemDetails | void>>(
 studentId: string,
    params?: GetApiV1EssayStudentStudentIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Lists all essays available to the student together with the essays already assigned
 */

export function useGetApiV1EssayStudentStudentId<TData = Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>, TError = AxiosError<ProblemDetails | void>>(
 studentId: string,
    params?: GetApiV1EssayStudentStudentIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1EssayStudentStudentId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1EssayStudentStudentIdQueryOptions(studentId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Changes the teacher of a given essay
 */
export const putApiV1EssayChangeTeacher = (
    changeEssayTeacherViewModel: ChangeEssayTeacherViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BooleanResultViewModel>> => {
    
    return axios.put(
      `/api/v1/essay/change-teacher`,
      changeEssayTeacherViewModel,options
    );
  }



export const getPutApiV1EssayChangeTeacherMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayChangeTeacher>>, TError,{data: ChangeEssayTeacherViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayChangeTeacher>>, TError,{data: ChangeEssayTeacherViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1EssayChangeTeacher>>, {data: ChangeEssayTeacherViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1EssayChangeTeacher(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1EssayChangeTeacherMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1EssayChangeTeacher>>>
    export type PutApiV1EssayChangeTeacherMutationBody = ChangeEssayTeacherViewModel
    export type PutApiV1EssayChangeTeacherMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Changes the teacher of a given essay
 */
export const usePutApiV1EssayChangeTeacher = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayChangeTeacher>>, TError,{data: ChangeEssayTeacherViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1EssayChangeTeacher>>,
        TError,
        {data: ChangeEssayTeacherViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1EssayChangeTeacherMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Submits an essay for review. After this step the draft cannot be changed anymore.
 */
export const putApiV1EssayEssayIdSubmit = (
    essayId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BooleanResultViewModel>> => {
    
    return axios.put(
      `/api/v1/essay/${essayId}/submit`,undefined,options
    );
  }



export const getPutApiV1EssayEssayIdSubmitMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdSubmit>>, TError,{essayId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdSubmit>>, TError,{essayId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1EssayEssayIdSubmit>>, {essayId: string}> = (props) => {
          const {essayId} = props ?? {};

          return  putApiV1EssayEssayIdSubmit(essayId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1EssayEssayIdSubmitMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1EssayEssayIdSubmit>>>
    
    export type PutApiV1EssayEssayIdSubmitMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Submits an essay for review. After this step the draft cannot be changed anymore.
 */
export const usePutApiV1EssayEssayIdSubmit = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdSubmit>>, TError,{essayId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1EssayEssayIdSubmit>>,
        TError,
        {essayId: string},
        TContext
      > => {

      const mutationOptions = getPutApiV1EssayEssayIdSubmitMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Closes the Essay.
From that part the essay is read-only
 */
export const putApiV1EssayEssayIdCloseReview = (
    essayId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BooleanResultViewModel>> => {
    
    return axios.put(
      `/api/v1/essay/${essayId}/close-review`,undefined,options
    );
  }



export const getPutApiV1EssayEssayIdCloseReviewMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdCloseReview>>, TError,{essayId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdCloseReview>>, TError,{essayId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1EssayEssayIdCloseReview>>, {essayId: string}> = (props) => {
          const {essayId} = props ?? {};

          return  putApiV1EssayEssayIdCloseReview(essayId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1EssayEssayIdCloseReviewMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1EssayEssayIdCloseReview>>>
    
    export type PutApiV1EssayEssayIdCloseReviewMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Closes the Essay.
From that part the essay is read-only
 */
export const usePutApiV1EssayEssayIdCloseReview = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdCloseReview>>, TError,{essayId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1EssayEssayIdCloseReview>>,
        TError,
        {essayId: string},
        TContext
      > => {

      const mutationOptions = getPutApiV1EssayEssayIdCloseReviewMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Finishes the review from the teacher's part
From now on the student can see the comments and grades and
can make comments
 */
export const putApiV1EssayEssayIdFinishReview = (
    essayId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BooleanResultViewModel>> => {
    
    return axios.put(
      `/api/v1/essay/${essayId}/finish-review`,undefined,options
    );
  }



export const getPutApiV1EssayEssayIdFinishReviewMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdFinishReview>>, TError,{essayId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdFinishReview>>, TError,{essayId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1EssayEssayIdFinishReview>>, {essayId: string}> = (props) => {
          const {essayId} = props ?? {};

          return  putApiV1EssayEssayIdFinishReview(essayId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1EssayEssayIdFinishReviewMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1EssayEssayIdFinishReview>>>
    
    export type PutApiV1EssayEssayIdFinishReviewMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Finishes the review from the teacher's part
From now on the student can see the comments and grades and
can make comments
 */
export const usePutApiV1EssayEssayIdFinishReview = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdFinishReview>>, TError,{essayId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1EssayEssayIdFinishReview>>,
        TError,
        {essayId: string},
        TContext
      > => {

      const mutationOptions = getPutApiV1EssayEssayIdFinishReviewMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Provides the grades to essay's competencies.
Note that it is only possible when the essay is being reviewed
 */
export const putApiV1EssayEssayIdUpdateGrades = (
    essayId: string,
    listOfEssayCompetenciesViewModel: ListOfEssayCompetenciesViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BooleanResultViewModel>> => {
    
    return axios.put(
      `/api/v1/essay/${essayId}/update-grades`,
      listOfEssayCompetenciesViewModel,options
    );
  }



export const getPutApiV1EssayEssayIdUpdateGradesMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdUpdateGrades>>, TError,{essayId: string;data: ListOfEssayCompetenciesViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdUpdateGrades>>, TError,{essayId: string;data: ListOfEssayCompetenciesViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1EssayEssayIdUpdateGrades>>, {essayId: string;data: ListOfEssayCompetenciesViewModel}> = (props) => {
          const {essayId,data} = props ?? {};

          return  putApiV1EssayEssayIdUpdateGrades(essayId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1EssayEssayIdUpdateGradesMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1EssayEssayIdUpdateGrades>>>
    export type PutApiV1EssayEssayIdUpdateGradesMutationBody = ListOfEssayCompetenciesViewModel
    export type PutApiV1EssayEssayIdUpdateGradesMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Provides the grades to essay's competencies.
Note that it is only possible when the essay is being reviewed
 */
export const usePutApiV1EssayEssayIdUpdateGrades = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdUpdateGrades>>, TError,{essayId: string;data: ListOfEssayCompetenciesViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1EssayEssayIdUpdateGrades>>,
        TError,
        {essayId: string;data: ListOfEssayCompetenciesViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1EssayEssayIdUpdateGradesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates the competency of a given essay comment
 */
export const putApiV1EssayEssayIdUpdateCompetencyComment = (
    essayId: string,
    updateCommentCompetency: UpdateCommentCompetency, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BooleanResultViewModel>> => {
    
    return axios.put(
      `/api/v1/essay/${essayId}/update-competency-comment`,
      updateCommentCompetency,options
    );
  }



export const getPutApiV1EssayEssayIdUpdateCompetencyCommentMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdUpdateCompetencyComment>>, TError,{essayId: string;data: UpdateCommentCompetency}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdUpdateCompetencyComment>>, TError,{essayId: string;data: UpdateCommentCompetency}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1EssayEssayIdUpdateCompetencyComment>>, {essayId: string;data: UpdateCommentCompetency}> = (props) => {
          const {essayId,data} = props ?? {};

          return  putApiV1EssayEssayIdUpdateCompetencyComment(essayId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1EssayEssayIdUpdateCompetencyCommentMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1EssayEssayIdUpdateCompetencyComment>>>
    export type PutApiV1EssayEssayIdUpdateCompetencyCommentMutationBody = UpdateCommentCompetency
    export type PutApiV1EssayEssayIdUpdateCompetencyCommentMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates the competency of a given essay comment
 */
export const usePutApiV1EssayEssayIdUpdateCompetencyComment = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayEssayIdUpdateCompetencyComment>>, TError,{essayId: string;data: UpdateCommentCompetency}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1EssayEssayIdUpdateCompetencyComment>>,
        TError,
        {essayId: string;data: UpdateCommentCompetency},
        TContext
      > => {

      const mutationOptions = getPutApiV1EssayEssayIdUpdateCompetencyCommentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Removes a comment. Note that now only the comment's owner is able to remove a comment.
user removal of comment does not erase it, unless removal is requested by UserRole OWNER.
 */
export const deleteApiV1EssayEssayIdCommentsCommentId = (
    essayId: string,
    commentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BooleanResultViewModel>> => {
    
    return axios.delete(
      `/api/v1/essay/${essayId}/comments/${commentId}`,options
    );
  }



export const getDeleteApiV1EssayEssayIdCommentsCommentIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1EssayEssayIdCommentsCommentId>>, TError,{essayId: string;commentId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1EssayEssayIdCommentsCommentId>>, TError,{essayId: string;commentId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1EssayEssayIdCommentsCommentId>>, {essayId: string;commentId: string}> = (props) => {
          const {essayId,commentId} = props ?? {};

          return  deleteApiV1EssayEssayIdCommentsCommentId(essayId,commentId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1EssayEssayIdCommentsCommentIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1EssayEssayIdCommentsCommentId>>>
    
    export type DeleteApiV1EssayEssayIdCommentsCommentIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes a comment. Note that now only the comment's owner is able to remove a comment.
user removal of comment does not erase it, unless removal is requested by UserRole OWNER.
 */
export const useDeleteApiV1EssayEssayIdCommentsCommentId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1EssayEssayIdCommentsCommentId>>, TError,{essayId: string;commentId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV1EssayEssayIdCommentsCommentId>>,
        TError,
        {essayId: string;commentId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1EssayEssayIdCommentsCommentIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Returns essay back to draft (if is with SUBMITTED status) and logged user is the teacher assigned to it.
Comments made on the essay body will be lost.
 */
export const putApiV1EssayReturnEssayToDraft = (
    returnEssayViewModel: ReturnEssayViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BooleanResultViewModel>> => {
    
    return axios.put(
      `/api/v1/essay/return-essay-to-draft`,
      returnEssayViewModel,options
    );
  }



export const getPutApiV1EssayReturnEssayToDraftMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayReturnEssayToDraft>>, TError,{data: ReturnEssayViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayReturnEssayToDraft>>, TError,{data: ReturnEssayViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1EssayReturnEssayToDraft>>, {data: ReturnEssayViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1EssayReturnEssayToDraft(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1EssayReturnEssayToDraftMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1EssayReturnEssayToDraft>>>
    export type PutApiV1EssayReturnEssayToDraftMutationBody = ReturnEssayViewModel
    export type PutApiV1EssayReturnEssayToDraftMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Returns essay back to draft (if is with SUBMITTED status) and logged user is the teacher assigned to it.
Comments made on the essay body will be lost.
 */
export const usePutApiV1EssayReturnEssayToDraft = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EssayReturnEssayToDraft>>, TError,{data: ReturnEssayViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1EssayReturnEssayToDraft>>,
        TError,
        {data: ReturnEssayViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1EssayReturnEssayToDraftMutationOptions(options);

      return useMutation(mutationOptions);
    }
    