/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */
import type { KeyValueViewModel } from './keyValueViewModel';

export interface TeacherGroupViewModel {
  blocked?: boolean;
  deleted?: boolean;
  /** @nullable */
  email?: string | null;
  /** @nullable */
  groups?: KeyValueViewModel[] | null;
  id?: string;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  phoneNumber?: string | null;
  /** @nullable */
  role?: string | null;
}
