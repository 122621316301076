/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */

export interface NewSchoolViewModel {
  /** @nullable */
  addressId?: string | null;
  /**
   * @minLength 1
   * @maxLength 100
   */
  document: string;
  /**
   * @minLength 5
   * @maxLength 150
   */
  name: string;
  /** @nullable */
  thumbnail?: string | null;
}
