import { Code, Heading, Image, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'

export function MarkdownRenderer({ markdownText }) {
  return (
    <ReactMarkdown
      components={{
        h1: ({ node, ...props }) => <Heading as="h1" size="2xl" my={4} {...props} />,
        h2: ({ node, ...props }) => <Heading as="h2" size="xl" my={4} {...props} />,
        h3: ({ node, ...props }) => <Heading as="h3" size="lg" my={4} {...props} />,
        p: ({ node, ...props }) => <Text mb={4} {...props} />,
        strong: ({ node, ...props }) => <Text as="strong" fontWeight="bold" {...props} />,
        em: ({ node, ...props }) => <Text as="em" fontStyle="italic" {...props} />,
        ul: ({ node, ...props }) => <UnorderedList mb={4} ml={6} {...props} />,
        li: ({ node, ...props }) => <ListItem mb={1} {...props} />,
        code: ({ node, inline, className, children, ...props }) => {
          return inline ? (
            <Code px={1} {...props}>
              {children}
            </Code>
          ) : (
            <Code display="block" p={4} mb={4} whiteSpace="pre-wrap" {...props}>
              {children}
            </Code>
          )
        },
        a: ({ node, ...props }) => <Link color="teal.500" {...props} />,
        img: ({ node, ...props }) => <Image my={4} {...props} />,
      }}>
      {markdownText}
    </ReactMarkdown>
  )
}
