import { type ChangeEvent, useMemo, useState } from 'react'
import '../GroupsArea.css'
import { useGetApiV1StudentSchoolSchoolId } from '@/services/student'
import { useGetApiV1Teacher } from '@/services/teacher'

import { AreaHeader } from '@/components/AreaHeader'
import SearchComponent from '@/components/SearchComponent'
import LayoutMenu from '@/layouts/LayoutMenu'
import type { SchoolTeacherViewModel } from '@/services/types'
import { useIsHistoryEmpty } from '@/utils/useNavigationHooks'
import { Flex, HStack, Text } from '@chakra-ui/react'
import { Box, Image } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import ActiveStatusCard from '../../../components/ActiveStatusCard'
import SharedTablePagination from '../../../components/SharedTablePagination'
import useLoggedUserStore from '../../../store/useLoggedUserStore'
import { shiftMapping } from '../../../utils/shiftENUM'
import Page from '../../Page'

function TeacherGroupsArea() {
  const { loggedUser } = useLoggedUserStore()
  const [filterText, setFilterText] = useState('')
  const navigate = useNavigate()

  const getApiV1StudentSchool = useGetApiV1StudentSchoolSchoolId(loggedUser.schoolId)
  const getApiV1Teacher = useGetApiV1Teacher()

  const getSchoolGroups = (schools?: SchoolTeacherViewModel[] | null, id?: string) =>
    schools?.filter((school) => school?.id === id).flatMap((school) => school?.groups || []) || []

  const groupsData = useMemo(() => {
    if (!getApiV1Teacher.isSuccess) return []

    if (loggedUser.user_role === 'independentteacher') {
      return getSchoolGroups(getApiV1Teacher.data?.data?.message?.schools, loggedUser.user_id)
    }
    return getSchoolGroups(getApiV1Teacher.data?.data?.message?.schools, loggedUser.schoolId)
  }, [getApiV1Teacher.isSuccess, loggedUser.user_role, loggedUser.user_id, loggedUser.schoolId])

  const groups =
    groupsData.filter((item) => {
      const nameMatch = item?.name?.toLowerCase().includes(filterText.toLowerCase())
      const shiftMatch = shiftMapping[item.shift as keyof typeof shiftMapping].toLowerCase().includes(filterText.toLowerCase())
      return nameMatch || shiftMatch
    }) || []
  const students = getApiV1StudentSchool.data?.data?.message || []

  useIsHistoryEmpty()

  function groupNameHandler(turma) {
    return (
      <div className="atividade-txt-area-de-turmas">
        <div className="frame-2580-area-de-turmas">
          <div className="x1-ano-area-de-turmas valign-text-middle-area-de-turmas publicsans-normal-black-12px">{turma}</div>
        </div>
      </div>
    )
  }

  function shiftHandler(turno) {
    return (
      <div className="atividade-txt-2-area-de-turmas">
        <div className="frame-2580-2-area-de-turmas">
          <div className="noite-area-de-turmas valign-text-middle-area-de-turmas publicsans-bold-absolute-zero-12px">
            {shiftMapping[turno]}
          </div>
        </div>
      </div>
    )
  }

  function statusHandler() {
    return (
      <div className="atividade-txt-2-area-de-atividades">
        <div className="frame-2581-1-area-de-atividades">
          <div className="publicsans-normal-black-12px">Ativa</div>
        </div>
      </div>
    )
  }

  function detailsHandler(groupId) {
    return (
      <Box className="atividade-txt-4-area-de-turmas" onClick={() => navigate(`/detalhamento-turma/${groupId}`)}>
        <Image className="ic-arrowright-area-de-turmas" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
      </Box>
    )
  }

  function handleSearchInputChange(e: ChangeEvent<HTMLInputElement>) {
    const inputText = e.currentTarget.value
    setFilterText(inputText)
  }

  return (
    <Page authPage={true} allowedUserType={'teacher independentteacher'}>
      <LayoutMenu isLoading={getApiV1StudentSchool?.isLoading || getApiV1Teacher.isLoading}>
        <LayoutMenu.Content>
          <AreaHeader
            title="Área de Turmas"
            button={{
              isVisible: loggedUser.user_role === 'independentteacher',
              navigateTo: '/criar-turma',
              label: 'Criar turma',
            }}
          />
          <SearchComponent filterText={filterText} handleSearchInputChange={handleSearchInputChange} />

          <HStack flexDir={{ md: 'row', base: 'column' }} className="status-area-de-turmas">
            <ActiveStatusCard imagePath="/img/folder-2851794@2x.png" title="Turmas ativas" amount={groupsData.length || 0} />
            <ActiveStatusCard imagePath="/img/folder-2851794@2x.png" title="Alunos ativos" amount={students.length || 0} />
          </HStack>
          {groups?.length === 0 ? (
            <Flex alignSelf={'stretch'} backgroundColor={'#f3f5ff'} height={'64px'} marginRight={'32px'} borderRadius={'16px'}>
              <Text className={'publicsans-normal-gray-14px'} width={'100%'} textAlign={'center'} alignSelf={'center'}>
                Nenhuma turma foi associado ao seu perfil ainda.
              </Text>
            </Flex>
          ) : (
            <SharedTablePagination
              data={groups}
              itemsPerPage={5}
              columns={[
                {
                  title: 'Turma',
                  render: (itemData) => <div>{groupNameHandler(itemData.name)}</div>,
                },
                {
                  title: 'Turno',
                  render: (itemData) => <div>{shiftHandler(itemData.shift)}</div>,
                },
                {
                  title: 'Status',
                  render: () => <div>{statusHandler()}</div>,
                },
                {
                  title: 'Detalhes',
                  render: (itemData) => <div>{detailsHandler(itemData.id)}</div>,
                },
              ]}
            />
          )}
        </LayoutMenu.Content>
      </LayoutMenu>
    </Page>
  )
}

export default TeacherGroupsArea
