/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */

export interface SupportMaterialViewModel {
  /** @nullable */
  fileName?: string | null;
  id?: string;
  /** @nullable */
  title?: string | null;
  type?: number;
  /** @nullable */
  typeDescription?: string | null;
  /** @nullable */
  value?: string | null;
}
