/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */
import type { EssayCommentType } from './essayCommentType';

export interface NewCommentViewModel {
  /** @nullable */
  comment?: string | null;
  commentType?: EssayCommentType;
  /** @nullable */
  competencyId?: string | null;
  endPos?: number;
  /** @nullable */
  endPosX?: number | null;
  /** @nullable */
  endPosY?: number | null;
  /** @nullable */
  lineHeight?: number | null;
  /** @nullable */
  parentId?: string | null;
  startPos?: number;
  /** @nullable */
  startPosX?: number | null;
  /** @nullable */
  startPosY?: number | null;
}
