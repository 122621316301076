import './Data.css'
import { Image } from '@chakra-ui/react'
import { abbreviatedMonth } from '../../utils/DateSatinizerInput'

function Data(props) {
  const { editSquare2851790, date } = props

  return (
    <div className="data">
      <Image className="image-style" src={editSquare2851790} alt="image" />
      <div className="date publicsans-normal-nevada-14px">{abbreviatedMonth(date)}</div>
    </div>
  )
}

export default Data
