/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */
import type { KeyValueViewModel } from './keyValueViewModel';

export interface EssayStudentViewModel {
  activity?: KeyValueViewModel;
  /** @nullable */
  dateSubmitted?: string | null;
  finalGrade?: number;
  /** @nullable */
  groups?: KeyValueViewModel[] | null;
  id?: string;
  school?: KeyValueViewModel;
  status?: number;
  teacher?: KeyValueViewModel;
  theme?: KeyValueViewModel;
}
