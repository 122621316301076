/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AddTeacherToSchoolAndGroupsViewModel,
  AdmUpdateTeacherViewModel,
  DeleteApiV1TeacherIndependentTeacherIdParams,
  KeyValueViewModelListResultViewModel,
  ListGroups,
  NewIndependentTeacherViewModel,
  NewMonitorViewModel,
  NewTeacherViewModel,
  ProblemDetails,
  SimpleTeacherViewModelListResultViewModel,
  TeacherGroupViewModelListResultViewModel,
  TeacherSchoolViewModelListResultViewModel,
  TeacherSchoolViewModelResultViewModel,
  UpdateIndependentTeacherViewModel,
  UpdateTeacherViewModel,
  UserGroupsViewModel
} from './types'




/**
 * @summary Creates a new independent teacher together with its default school and group
 */
export const postApiV1TeacherIndependentTeacher = (
    newIndependentTeacherViewModel: NewIndependentTeacherViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/teacher/independentTeacher`,
      newIndependentTeacherViewModel,options
    );
  }



export const getPostApiV1TeacherIndependentTeacherMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1TeacherIndependentTeacher>>, TError,{data: NewIndependentTeacherViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1TeacherIndependentTeacher>>, TError,{data: NewIndependentTeacherViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1TeacherIndependentTeacher>>, {data: NewIndependentTeacherViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1TeacherIndependentTeacher(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1TeacherIndependentTeacherMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1TeacherIndependentTeacher>>>
    export type PostApiV1TeacherIndependentTeacherMutationBody = NewIndependentTeacherViewModel
    export type PostApiV1TeacherIndependentTeacherMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Creates a new independent teacher together with its default school and group
 */
export const usePostApiV1TeacherIndependentTeacher = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1TeacherIndependentTeacher>>, TError,{data: NewIndependentTeacherViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1TeacherIndependentTeacher>>,
        TError,
        {data: NewIndependentTeacherViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1TeacherIndependentTeacherMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates basic info from Independent teacher
 */
export const putApiV1TeacherIndependentTeacher = (
    updateIndependentTeacherViewModel: UpdateIndependentTeacherViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/teacher/independentTeacher`,
      updateIndependentTeacherViewModel,options
    );
  }



export const getPutApiV1TeacherIndependentTeacherMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1TeacherIndependentTeacher>>, TError,{data: UpdateIndependentTeacherViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1TeacherIndependentTeacher>>, TError,{data: UpdateIndependentTeacherViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1TeacherIndependentTeacher>>, {data: UpdateIndependentTeacherViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1TeacherIndependentTeacher(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1TeacherIndependentTeacherMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1TeacherIndependentTeacher>>>
    export type PutApiV1TeacherIndependentTeacherMutationBody = UpdateIndependentTeacherViewModel
    export type PutApiV1TeacherIndependentTeacherMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates basic info from Independent teacher
 */
export const usePutApiV1TeacherIndependentTeacher = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1TeacherIndependentTeacher>>, TError,{data: UpdateIndependentTeacherViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1TeacherIndependentTeacher>>,
        TError,
        {data: UpdateIndependentTeacherViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1TeacherIndependentTeacherMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Cancel the Independent teacher account together with its school
 */
export const deleteApiV1TeacherIndependentTeacherId = (
    id: string,
    params?: DeleteApiV1TeacherIndependentTeacherIdParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/v1/teacher/independentTeacher/${id}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteApiV1TeacherIndependentTeacherIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1TeacherIndependentTeacherId>>, TError,{id: string;params?: DeleteApiV1TeacherIndependentTeacherIdParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1TeacherIndependentTeacherId>>, TError,{id: string;params?: DeleteApiV1TeacherIndependentTeacherIdParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1TeacherIndependentTeacherId>>, {id: string;params?: DeleteApiV1TeacherIndependentTeacherIdParams}> = (props) => {
          const {id,params} = props ?? {};

          return  deleteApiV1TeacherIndependentTeacherId(id,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1TeacherIndependentTeacherIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1TeacherIndependentTeacherId>>>
    
    export type DeleteApiV1TeacherIndependentTeacherIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Cancel the Independent teacher account together with its school
 */
export const useDeleteApiV1TeacherIndependentTeacherId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1TeacherIndependentTeacherId>>, TError,{id: string;params?: DeleteApiV1TeacherIndependentTeacherIdParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV1TeacherIndependentTeacherId>>,
        TError,
        {id: string;params?: DeleteApiV1TeacherIndependentTeacherIdParams},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1TeacherIndependentTeacherIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Creates a new teacher
 */
export const postApiV1Teacher = (
    newTeacherViewModel: NewTeacherViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/teacher`,
      newTeacherViewModel,options
    );
  }



export const getPostApiV1TeacherMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Teacher>>, TError,{data: NewTeacherViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1Teacher>>, TError,{data: NewTeacherViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1Teacher>>, {data: NewTeacherViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1Teacher(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1TeacherMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1Teacher>>>
    export type PostApiV1TeacherMutationBody = NewTeacherViewModel
    export type PostApiV1TeacherMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Creates a new teacher
 */
export const usePostApiV1Teacher = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Teacher>>, TError,{data: NewTeacherViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1Teacher>>,
        TError,
        {data: NewTeacherViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1TeacherMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Returns all details from logged in teacher.
 */
export const getApiV1Teacher = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TeacherSchoolViewModelResultViewModel>> => {
    
    return axios.get(
      `/api/v1/teacher`,options
    );
  }


export const getGetApiV1TeacherQueryKey = () => {
    return [`/api/v1/teacher`] as const;
    }

    
export const getGetApiV1TeacherQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1Teacher>>, TError = AxiosError<ProblemDetails | void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1Teacher>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1TeacherQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Teacher>>> = ({ signal }) => getApiV1Teacher({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1Teacher>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1TeacherQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Teacher>>>
export type GetApiV1TeacherQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1Teacher<TData = Awaited<ReturnType<typeof getApiV1Teacher>>, TError = AxiosError<ProblemDetails | void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1Teacher>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1Teacher>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1Teacher<TData = Awaited<ReturnType<typeof getApiV1Teacher>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1Teacher>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1Teacher>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1Teacher<TData = Awaited<ReturnType<typeof getApiV1Teacher>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1Teacher>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns all details from logged in teacher.
 */

export function useGetApiV1Teacher<TData = Awaited<ReturnType<typeof getApiV1Teacher>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1Teacher>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1TeacherQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Updates logged teacher info
 */
export const putApiV1Teacher = (
    updateTeacherViewModel: UpdateTeacherViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/teacher`,
      updateTeacherViewModel,options
    );
  }



export const getPutApiV1TeacherMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1Teacher>>, TError,{data: UpdateTeacherViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1Teacher>>, TError,{data: UpdateTeacherViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1Teacher>>, {data: UpdateTeacherViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1Teacher(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1TeacherMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1Teacher>>>
    export type PutApiV1TeacherMutationBody = UpdateTeacherViewModel
    export type PutApiV1TeacherMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates logged teacher info
 */
export const usePutApiV1Teacher = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1Teacher>>, TError,{data: UpdateTeacherViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1Teacher>>,
        TError,
        {data: UpdateTeacherViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1TeacherMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Removes a teacher from the system
 */
export const deleteApiV1TeacherId = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/v1/teacher/${id}`,options
    );
  }



export const getDeleteApiV1TeacherIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1TeacherId>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1TeacherId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1TeacherId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteApiV1TeacherId(id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1TeacherIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1TeacherId>>>
    
    export type DeleteApiV1TeacherIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes a teacher from the system
 */
export const useDeleteApiV1TeacherId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1TeacherId>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV1TeacherId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1TeacherIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary List all teachers from a certain school. It also validates if the logged user has access to this school
 */
export const getApiV1TeacherSchoolSchoolId = (
    schoolId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TeacherGroupViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/teacher/school/${schoolId}`,options
    );
  }


export const getGetApiV1TeacherSchoolSchoolIdQueryKey = (schoolId: string,) => {
    return [`/api/v1/teacher/school/${schoolId}`] as const;
    }

    
export const getGetApiV1TeacherSchoolSchoolIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1TeacherSchoolSchoolIdQueryKey(schoolId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>> = ({ signal }) => getApiV1TeacherSchoolSchoolId(schoolId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(schoolId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1TeacherSchoolSchoolIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>>
export type GetApiV1TeacherSchoolSchoolIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1TeacherSchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1TeacherSchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1TeacherSchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary List all teachers from a certain school. It also validates if the logged user has access to this school
 */

export function useGetApiV1TeacherSchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1TeacherSchoolSchoolIdQueryOptions(schoolId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Lists the teachers that belong to all given groups
 */
export const patchApiV1TeacherListFromGroups = (
    listGroups: ListGroups, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<KeyValueViewModelListResultViewModel>> => {
    
    return axios.patch(
      `/api/v1/teacher/list-from-groups`,
      listGroups,options
    );
  }



export const getPatchApiV1TeacherListFromGroupsMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV1TeacherListFromGroups>>, TError,{data: ListGroups}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV1TeacherListFromGroups>>, TError,{data: ListGroups}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV1TeacherListFromGroups>>, {data: ListGroups}> = (props) => {
          const {data} = props ?? {};

          return  patchApiV1TeacherListFromGroups(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV1TeacherListFromGroupsMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV1TeacherListFromGroups>>>
    export type PatchApiV1TeacherListFromGroupsMutationBody = ListGroups
    export type PatchApiV1TeacherListFromGroupsMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Lists the teachers that belong to all given groups
 */
export const usePatchApiV1TeacherListFromGroups = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV1TeacherListFromGroups>>, TError,{data: ListGroups}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV1TeacherListFromGroups>>,
        TError,
        {data: ListGroups},
        TContext
      > => {

      const mutationOptions = getPatchApiV1TeacherListFromGroupsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary List all teachers from a certain school and group. It also validates if the logged user has access to this school
 */
export const getApiV1TeacherSchoolSchoolIdGroupId = (
    schoolId: string,
    groupId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SimpleTeacherViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/teacher/school/${schoolId}/${groupId}`,options
    );
  }


export const getGetApiV1TeacherSchoolSchoolIdGroupIdQueryKey = (schoolId: string,
    groupId: string,) => {
    return [`/api/v1/teacher/school/${schoolId}/${groupId}`] as const;
    }

    
export const getGetApiV1TeacherSchoolSchoolIdGroupIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>, TError = AxiosError<ProblemDetails | void>>(schoolId: string,
    groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1TeacherSchoolSchoolIdGroupIdQueryKey(schoolId,groupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>> = ({ signal }) => getApiV1TeacherSchoolSchoolIdGroupId(schoolId,groupId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(schoolId && groupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1TeacherSchoolSchoolIdGroupIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>>
export type GetApiV1TeacherSchoolSchoolIdGroupIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1TeacherSchoolSchoolIdGroupId<TData = Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    groupId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1TeacherSchoolSchoolIdGroupId<TData = Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1TeacherSchoolSchoolIdGroupId<TData = Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary List all teachers from a certain school and group. It also validates if the logged user has access to this school
 */

export function useGetApiV1TeacherSchoolSchoolIdGroupId<TData = Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string,
    groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherSchoolSchoolIdGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1TeacherSchoolSchoolIdGroupIdQueryOptions(schoolId,groupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Returns all details from a specific teacher.
Note that this endpoint only shows schools attached to this teacher that logged user is the ADM (or owner)
 */
export const getApiV1TeacherTeacherId = (
    teacherId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TeacherSchoolViewModelResultViewModel>> => {
    
    return axios.get(
      `/api/v1/teacher/${teacherId}`,options
    );
  }


export const getGetApiV1TeacherTeacherIdQueryKey = (teacherId: string,) => {
    return [`/api/v1/teacher/${teacherId}`] as const;
    }

    
export const getGetApiV1TeacherTeacherIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(teacherId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1TeacherTeacherIdQueryKey(teacherId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>> = ({ signal }) => getApiV1TeacherTeacherId(teacherId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(teacherId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1TeacherTeacherIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>>
export type GetApiV1TeacherTeacherIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1TeacherTeacherId<TData = Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(
 teacherId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1TeacherTeacherId<TData = Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(
 teacherId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1TeacherTeacherId<TData = Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(
 teacherId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns all details from a specific teacher.
Note that this endpoint only shows schools attached to this teacher that logged user is the ADM (or owner)
 */

export function useGetApiV1TeacherTeacherId<TData = Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(
 teacherId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherTeacherId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1TeacherTeacherIdQueryOptions(teacherId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Updates teacher info (ADM only)
 */
export const putApiV1TeacherTeacherId = (
    teacherId: string,
    admUpdateTeacherViewModel: AdmUpdateTeacherViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/teacher/${teacherId}`,
      admUpdateTeacherViewModel,options
    );
  }



export const getPutApiV1TeacherTeacherIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1TeacherTeacherId>>, TError,{teacherId: string;data: AdmUpdateTeacherViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1TeacherTeacherId>>, TError,{teacherId: string;data: AdmUpdateTeacherViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1TeacherTeacherId>>, {teacherId: string;data: AdmUpdateTeacherViewModel}> = (props) => {
          const {teacherId,data} = props ?? {};

          return  putApiV1TeacherTeacherId(teacherId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1TeacherTeacherIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1TeacherTeacherId>>>
    export type PutApiV1TeacherTeacherIdMutationBody = AdmUpdateTeacherViewModel
    export type PutApiV1TeacherTeacherIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates teacher info (ADM only)
 */
export const usePutApiV1TeacherTeacherId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1TeacherTeacherId>>, TError,{teacherId: string;data: AdmUpdateTeacherViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1TeacherTeacherId>>,
        TError,
        {teacherId: string;data: AdmUpdateTeacherViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1TeacherTeacherIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Returns all details from teacher that belongs to schools of the logged ADM/IT
 */
export const getApiV1TeacherTeacherAdm = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TeacherSchoolViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/teacher/teacher-adm`,options
    );
  }


export const getGetApiV1TeacherTeacherAdmQueryKey = () => {
    return [`/api/v1/teacher/teacher-adm`] as const;
    }

    
export const getGetApiV1TeacherTeacherAdmQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>, TError = AxiosError<ProblemDetails | void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1TeacherTeacherAdmQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>> = ({ signal }) => getApiV1TeacherTeacherAdm({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1TeacherTeacherAdmQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>>
export type GetApiV1TeacherTeacherAdmQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1TeacherTeacherAdm<TData = Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>, TError = AxiosError<ProblemDetails | void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1TeacherTeacherAdm<TData = Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1TeacherTeacherAdm<TData = Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns all details from teacher that belongs to schools of the logged ADM/IT
 */

export function useGetApiV1TeacherTeacherAdm<TData = Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1TeacherTeacherAdm>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1TeacherTeacherAdmQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Adds an existing teacher to another school. The logged user needs to be the adm of the target school
 */
export const putApiV1TeacherAddToSchool = (
    addTeacherToSchoolAndGroupsViewModel: AddTeacherToSchoolAndGroupsViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/teacher/add-to-school`,
      addTeacherToSchoolAndGroupsViewModel,options
    );
  }



export const getPutApiV1TeacherAddToSchoolMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1TeacherAddToSchool>>, TError,{data: AddTeacherToSchoolAndGroupsViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1TeacherAddToSchool>>, TError,{data: AddTeacherToSchoolAndGroupsViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1TeacherAddToSchool>>, {data: AddTeacherToSchoolAndGroupsViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1TeacherAddToSchool(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1TeacherAddToSchoolMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1TeacherAddToSchool>>>
    export type PutApiV1TeacherAddToSchoolMutationBody = AddTeacherToSchoolAndGroupsViewModel
    export type PutApiV1TeacherAddToSchoolMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Adds an existing teacher to another school. The logged user needs to be the adm of the target school
 */
export const usePutApiV1TeacherAddToSchool = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1TeacherAddToSchool>>, TError,{data: AddTeacherToSchoolAndGroupsViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1TeacherAddToSchool>>,
        TError,
        {data: AddTeacherToSchoolAndGroupsViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1TeacherAddToSchoolMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Creates new monitor.
It is a special profile for Independent Teachers, therefore only IT can create new monitors
 */
export const postApiV1TeacherMonitor = (
    newMonitorViewModel: NewMonitorViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/teacher/monitor`,
      newMonitorViewModel,options
    );
  }



export const getPostApiV1TeacherMonitorMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1TeacherMonitor>>, TError,{data: NewMonitorViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1TeacherMonitor>>, TError,{data: NewMonitorViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1TeacherMonitor>>, {data: NewMonitorViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1TeacherMonitor(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1TeacherMonitorMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1TeacherMonitor>>>
    export type PostApiV1TeacherMonitorMutationBody = NewMonitorViewModel
    export type PostApiV1TeacherMonitorMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Creates new monitor.
It is a special profile for Independent Teachers, therefore only IT can create new monitors
 */
export const usePostApiV1TeacherMonitor = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1TeacherMonitor>>, TError,{data: NewMonitorViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1TeacherMonitor>>,
        TError,
        {data: NewMonitorViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1TeacherMonitorMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Attaches a teacher into many groups
 */
export const postApiV1TeacherAddToGroups = (
    userGroupsViewModel: UserGroupsViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/teacher/add-to-groups`,
      userGroupsViewModel,options
    );
  }



export const getPostApiV1TeacherAddToGroupsMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1TeacherAddToGroups>>, TError,{data: UserGroupsViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1TeacherAddToGroups>>, TError,{data: UserGroupsViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1TeacherAddToGroups>>, {data: UserGroupsViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1TeacherAddToGroups(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1TeacherAddToGroupsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1TeacherAddToGroups>>>
    export type PostApiV1TeacherAddToGroupsMutationBody = UserGroupsViewModel
    export type PostApiV1TeacherAddToGroupsMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Attaches a teacher into many groups
 */
export const usePostApiV1TeacherAddToGroups = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1TeacherAddToGroups>>, TError,{data: UserGroupsViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1TeacherAddToGroups>>,
        TError,
        {data: UserGroupsViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1TeacherAddToGroupsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Removes a teacher from many groups
 */
export const deleteApiV1TeacherRemoveFromGroups = (
    userGroupsViewModel: UserGroupsViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/v1/teacher/remove-from-groups`,{data:
      userGroupsViewModel, ...options}
    );
  }



export const getDeleteApiV1TeacherRemoveFromGroupsMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1TeacherRemoveFromGroups>>, TError,{data: UserGroupsViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1TeacherRemoveFromGroups>>, TError,{data: UserGroupsViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1TeacherRemoveFromGroups>>, {data: UserGroupsViewModel}> = (props) => {
          const {data} = props ?? {};

          return  deleteApiV1TeacherRemoveFromGroups(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1TeacherRemoveFromGroupsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1TeacherRemoveFromGroups>>>
    export type DeleteApiV1TeacherRemoveFromGroupsMutationBody = UserGroupsViewModel
    export type DeleteApiV1TeacherRemoveFromGroupsMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes a teacher from many groups
 */
export const useDeleteApiV1TeacherRemoveFromGroups = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1TeacherRemoveFromGroups>>, TError,{data: UserGroupsViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV1TeacherRemoveFromGroups>>,
        TError,
        {data: UserGroupsViewModel},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1TeacherRemoveFromGroupsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    