import { Flex } from '@chakra-ui/react'
import { useEffect } from 'react'
import useCreateActivityStore, { STEP_ACTIVITY } from '../../store/useCreateActivityStore'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import Page from '../Page'
import CreateActivityStep1 from './CreateActivityStep1'
import CreateActivityStep2 from './CreateActivityStep2'
import CreateActivityStep3 from './CreateActivityStep3'

const CriarAtividade = () => {
  const { currentStep, setCurrentStep, setActivityData } = useCreateActivityStore()
  const { loggedUser } = useLoggedUserStore()

  useEffect(() => {
    setActivityData({ schoolId: loggedUser.schoolId, teacherId: loggedUser.user_id })
    return () => setCurrentStep(STEP_ACTIVITY.ABOUT)
  }, [])

  const handleNextStep = () => setCurrentStep(currentStep + 1)
  const handlePreviousStep = () => setCurrentStep(currentStep - 1)

  const renderStepContent = () => {
    switch (currentStep) {
      case STEP_ACTIVITY.ABOUT:
        return <CreateActivityStep1 handlingNextStep={handleNextStep} />
      case STEP_ACTIVITY.CONFIGURATION:
        return <CreateActivityStep2 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep} />
      case STEP_ACTIVITY.CREATED:
        return <CreateActivityStep3 />
      default:
        return null
    }
  }

  return (
    <Page authPage={true} allowedUserType={'teacher independentteacher'}>
      <Flex alignItems={'center'} justifyContent={'center'} h={'100%'} w={'100%'}>
        {renderStepContent()}
      </Flex>
    </Page>
  )
}

export default CriarAtividade
