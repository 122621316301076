import { useEffect, useState } from 'react'
import './SchoolDetails.css'
import {
  Box,
  Button,
  HStack,
  Image,
  Popover,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { listAllInstitutionsFromSchool } from '../../apis/institutionApi/institutionApi'
import { listAllGroups } from '../../apis/schoolApi/groupApi'
import { deleteSchoolApi } from '../../apis/schoolApi/schoolApi'
import { listAllTeachers } from '../../apis/teacherApi/teacherApi'
import Headbar from '../../components/Headbar'
import InstitutionCard from '../../components/InstitutionCard'
import ModalEditAddressSchool from '../../components/ModalEditAddressSchool'
import ModalEditSchool from '../../components/ModalEditSchool'
import NoContentContainer from '../../components/NoContentContainer'
import PopoverAdicionarTurma from '../../components/PopoverAddGroup'
import PopoverAdicionarProfessor from '../../components/PopoverAdicionarProfessor'
import SearchComponent from '../../components/SearchComponent'
import SharedCardPagination from '../../components/SharedCardPagination'
import SharedTablePagination from '../../components/SharedTablePagination'
import { useGetApiV1SchoolId } from '../../services/school'
import useCreateInstitutionStore from '../../store/useCreateInstitutionStore'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import { shiftMapping } from '../../utils/shiftENUM'
import { buildMessage } from '../../utils/toastMiddleware'
import Page from '../Page'

function SchoolDetails() {
  const navigate = useNavigate()
  const { schoolId } = useParams()

  const { setLoggedUserData } = useLoggedUserStore()
  const { setCreateInstitutionData } = useCreateInstitutionStore()

  const [groupsData, setGroupsData] = useState([])
  const [teachersData, setTeachersData] = useState([])
  const [groupsOptions, setGroupsOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [teachersFilterText, setTeachersFilterText] = useState('')
  const [groupsFilterText, setGroupsFilterText] = useState('')
  const [selectedTab, setSelectedTab] = useState('groups')
  const [groups, setGroups] = useState([])
  const [teachers, setTeachers] = useState([])
  const { isOpenGroup, onOpenGroup, onCloseGroup } = useDisclosure()
  const { isOpenTeacher, onOpenTeacher, onCloseTeacher } = useDisclosure()

  const [institutions, setInstitutions] = useState([])
  const [institutionsData, setInstitutionsData] = useState()

  const modalDisclosure = useDisclosure()
  const modalAddressDisclosure = useDisclosure()
  const popoverDisclosure = useDisclosure()

  const toast = useToast()

  const itemsPerPage = window.innerWidth > 2390 ? 9 : 10

  const { data: school, isPending: isPendingUseGetApiV1SchoolId, refetch: refetchSchool } = useGetApiV1SchoolId(schoolId)

  const schoolData = school?.data?.message

  function fetchInitialData() {
    return new Promise((resolve) => {
      Promise.all([listAllGroups(schoolId), listAllTeachers(schoolId), listAllInstitutionsFromSchool(schoolId)]).then(
        ([groupsResult, teachersResult, institutionResult]) => {
          resolve({ groupsResult, teachersResult, institutionResult })
        },
      )
    })
  }

  async function fetchAllInstitutions() {
    const data = await listAllInstitutionsFromSchool(schoolId)
    setInstitutionsData(data)
  }

  useEffect(() => {
    fetchInitialData().then(({ groupsResult, teachersResult, institutionResult }) => {
      setGroupsData(groupsResult || {})
      setTeachersData(teachersResult || {})
      setInstitutionsData(institutionResult || {})
      setGroupsOptions(
        groupsResult.map((group, _key) => {
          return { value: group.name, label: group.name, id: group.id }
        }),
      )
      setLoading(false)
    })

    setLoggedUserData({ schoolId: schoolId })
  }, [])

  useEffect(() => {
    setInstitutions(institutionsData)
  }, [institutionsData])

  function handleInstitutionSearchInputChange(e) {
    const inputText = e.target.value

    setInstitutions(
      institutionsData.filter((item) => {
        return item.name.toLowerCase().includes(inputText.toLowerCase())
      }),
    )
  }

  function fetchGroups() {
    Promise.all([listAllGroups(schoolId)]).then(([groupsResult]) => {
      setGroupsData(groupsResult)
      setGroupsOptions(
        groupsResult.map((group) => ({
          value: group.name,
          label: group.name,
          id: group.id,
        })),
      )
    })
  }

  function fetchTeachers() {
    Promise.all([listAllTeachers(schoolId)]).then(([teachersResult]) => {
      setTeachersData(teachersResult)
    })
  }

  useEffect(() => {
    if (groupsData) {
      setGroups(
        groupsData.filter((item) => {
          const result =
            item.name.toLowerCase().includes(groupsFilterText.toLowerCase()) ||
            shiftMapping[item.shift].toLowerCase().includes(groupsFilterText.toLowerCase())

          return result
        }),
      )
    }
  }, [groupsFilterText, groupsData])

  useEffect(() => {
    if (teachersData) {
      setTeachers(
        teachersData.filter((item) => {
          const result =
            item.name.toLowerCase().includes(teachersFilterText.toLowerCase()) ||
            item.phoneNumber.toLowerCase().includes(teachersFilterText.toLowerCase()) ||
            item.email.toLowerCase().includes(teachersFilterText.toLowerCase())

          return result
        }),
      )
    }
  }, [teachersFilterText, teachersData])

  function handleGroupsSearchInputChange(e) {
    setGroupsFilterText(e.target.value)
  }

  function handleTeachersSearchInputChange(e) {
    setTeachersFilterText(e.target.value)
  }

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  function HandleTeacherDetails({ teacherId }) {
    return (
      <div className={'teste publicsans-normal-black-12px'}>
        {
          <Link to={`/detalhamento-professor/${teacherId}`} state={{ schoolId }}>
            <div className="atividade-txt-4-area-de-turmas">
              <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
            </div>
          </Link>
        }
      </div>
    )
  }

  function geradorTurma(turma) {
    return (
      <div className="atividade-txt-area-de-turmas">
        <div className="">
          <div className="x1-ano-area-de-turmas valign-text-middle-area-de-turmas publicsans-normal-black-14px">{turma}</div>
        </div>
      </div>
    )
  }

  function geradorTurno(turno) {
    return (
      <div className="atividade-txt-2-area-de-turmas">
        <div className="frame-2580-2-area-de-turmas">
          <div className="noite-area-de-turmas valign-text-middle-area-de-turmas publicsans-bold-absolute-zero-12px">
            {shiftMapping[turno]}
          </div>
        </div>
      </div>
    )
  }

  function geradorStatus() {
    return (
      <div className="atividade-txt-2-area-de-atividades">
        <div className="frame-2581-1-area-de-atividades">
          <div className="ativa-area-de-atividades valign-text-middle-area-de-atividades publicsans-normal-black-12px">
            Ativa
          </div>
        </div>
      </div>
    )
  }

  function geradorSetas(itemData) {
    return (
      <Link to={`/detalhamento-turma/${itemData}`} state={{ schoolId }}>
        <div className="atividade-txt-4-area-de-turmas">
          <img className="ic-arrowright-area-de-turmas" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
        </div>
      </Link>
    )
  }

  return (
    <div className="detalhamento-escola screen-detalhamento-escola">
      <Page authPage={true} allowedUserType={'adm'} />
      <VStack width={'100%'} alignSelf={'start'} gap={0}>
        <Headbar returnTo={'/area-de-escolas'} />
        <HStack
          width={'90%'}
          flexDir={{ md: 'row', base: 'column' }}
          gap={'16px'}
          justifyContent={'space-between'}
          className={'content-max-size'}>
          <VStack gap={5} pb={5} className={'detalhamento-escola-body'}>
            <VStack className="tema-detalhamento-escola">
              <HStack justifyContent={'space-between'} width={'100%'}>
                <VStack alignItems={'start'}>
                  <Text className="publicsans-bold-nevada-10px">ESCOLA</Text>
                  <HStack gap={5} flexDir={{ md: 'row', base: 'column' }} alignItems={'start'}>
                    <Text className="publicsans-normal-absolute-zero-24px">{schoolData?.name}</Text>
                    <Text
                      py={'8px'}
                      px={'16px'}
                      border={'1px solid'}
                      borderRadius={'8px'}
                      backgroundColor={'brand.400'}
                      borderColor={'brand.200'}
                      className="publicsans-normal-black-14px">
                      Ativa
                    </Text>
                  </HStack>
                </VStack>

                <HStack alignSelf={'start'} className="editar-detalhamento-escola">
                  <Text className="publicsans-bold-nevada-14px">{'Editar'}</Text>
                  <Image
                    className="mode-edit-detalhamento-escola"
                    src={'/img/mode-edit@2x.png'}
                    alt="Mode edit"
                    onClick={modalDisclosure.onOpen}
                  />
                  <ModalEditSchool
                    isOpen={modalDisclosure.isOpen}
                    onClose={modalDisclosure.onClose}
                    schoolId={schoolId}
                    schoolData={schoolData}
                    refetch={refetchSchool}
                  />
                </HStack>
              </HStack>

              <HStack
                justifyContent={'space-between'}
                width={'100%'}
                alignItems={'start'}
                flexDir={{ md: 'row', base: 'column' }}>
                <HStack gap={5}>
                  <Text fontSize={'12px'} className="publicsans-bold-nevada-10px">
                    CNPJ
                  </Text>
                  <div className="frame-2580-1-detalhamento-escola">
                    <Text className="publicsans-normal-black-12px">{schoolData?.document}</Text>
                  </div>
                </HStack>

                <HStack mt={3}>
                  <div className={'publicsans-bold-nevada-14px'}> Apagar escola?</div>
                  <Popover
                    isOpen={popoverDisclosure.isOpen}
                    onOpen={popoverDisclosure.onOpen}
                    onClose={popoverDisclosure.onClose}
                    placement="bottom-end"
                    closeOnBlur={false}>
                    <PopoverTrigger>
                      <Image src={'/img/deleteIcon.svg'} marginRight={'15px'} cursor={'pointer'} />
                    </PopoverTrigger>
                    <PopoverContent maxWidth={'300px'} width={['280px', '448px']} borderRadius={'10px'}>
                      <PopoverFooter alignSelf={'center'} width={'100%'}>
                        <VStack width={'100%'}>
                          <Button
                            colorScheme={'red'}
                            width={'100%'}
                            height={'48px'}
                            onClick={async () => {
                              await deleteSchoolApi(schoolId)
                              toast(buildMessage('success', 'Remoção de escola realizada com sucesso.', ''))
                              navigate('/area-de-escolas')
                            }}>
                            Remover
                          </Button>
                          <Button variant={'outline'} height={'48px'} width={'100%'} onClick={popoverDisclosure.onClose}>
                            Cancelar
                          </Button>
                        </VStack>
                      </PopoverFooter>
                    </PopoverContent>
                  </Popover>
                </HStack>
              </HStack>
            </VStack>
            <HStack maxW={'100%'} overflowX={'auto'} className="frame-2595-detalhamento-escola">
              <div
                className={`frame-2471-detalhamento-escola ${selectedTab === 'groups' ? 'tab-border' : ''}`}
                onClick={() => changeSelectedTab('groups')}>
                <div
                  className={`redaes-detalhamento-escola ${selectedTab === 'groups' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                  {'Turmas'}
                </div>
              </div>
              <div
                className={`frame-2470-detalhamento-escola ${selectedTab === 'teachers' ? 'tab-border' : ''}`}
                onClick={() => changeSelectedTab('teachers')}>
                <div
                  className={`atividades-vinculadas-detalhamento-escola ${selectedTab === 'teachers' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                  {'Professores'}
                </div>
              </div>
              <div
                className={`frame-2470-detalhamento-escola ${selectedTab === 'institutions' ? 'tab-border' : ''}`}
                onClick={() => changeSelectedTab('institutions')}>
                <div
                  className={`atividades-vinculadas-detalhamento-escola ${selectedTab === 'institutions' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                  {'Instituições'}
                </div>
              </div>
            </HStack>
            {selectedTab === 'groups' && (
              <div className={'redacoes-container-detalhamento-escola-style'}>
                <div className="search-filter-detalhamento-escola">
                  <div className="search-filtro-detalhamento-escola">
                    <HStack width={'100%'} alignItems="end">
                      <SearchComponent filterText={groupsFilterText} handleSearchInputChange={handleGroupsSearchInputChange} />

                      <div>
                        <PopoverAdicionarTurma
                          isOpen={isOpenGroup}
                          onClose={onCloseGroup}
                          onOpen={onOpenGroup}
                          fetchGroups={fetchGroups}
                          schoolId={schoolId}
                        />
                      </div>
                    </HStack>
                  </div>
                  {groupsData.length > 0 ? (
                    <SharedTablePagination
                      data={groups}
                      itemsPerPage={5}
                      columns={[
                        {
                          title: 'Turma',
                          render: (itemData) => <div>{geradorTurma(itemData.name)}</div>,
                        },
                        {
                          title: 'Turno',
                          render: (itemData) => <div>{geradorTurno(itemData.shift)}</div>,
                        },
                        {
                          title: 'Status',
                          render: (itemData) => <div>{geradorStatus(itemData.active)}</div>,
                        },
                        {
                          title: 'Detalhes',
                          render: (itemData) => <div>{geradorSetas(itemData.id)}</div>,
                        },
                      ]}
                    />
                  ) : (
                    <NoContentContainer>Não há turmas cadastradas nessa escola.</NoContentContainer>
                  )}
                </div>
              </div>
            )}

            {selectedTab === 'teachers' && (
              <div className={'redacoes-container-detalhamento-escola-style'}>
                <div className="search-filter-detalhamento-escola">
                  <div className="search-filtro-detalhamento-escola">
                    <HStack width={'100%'} alignItems="end">
                      <SearchComponent
                        filterText={teachersFilterText}
                        handleSearchInputChange={handleTeachersSearchInputChange}
                      />

                      <div>
                        <PopoverAdicionarProfessor
                          onOpen={onOpenTeacher}
                          isOpen={isOpenTeacher}
                          onClose={onCloseTeacher}
                          groupsOptions={groupsOptions}
                          fetchTeachers={fetchTeachers}
                          schoolId={schoolId}
                        />
                      </div>
                    </HStack>
                  </div>
                  {teachersData.length > 0 ? (
                    <SharedTablePagination
                      data={teachers}
                      itemsPerPage={5}
                      columns={[
                        {
                          title: 'Nome',
                          render: (itemData) => (
                            <div className={'table-cell-container publicsans-normal-black-14px'}>{itemData.name}</div>
                          ),
                        },
                        {
                          title: 'Email',
                          render: (itemData) => (
                            <div className={'table-cell-container publicsans-normal-black-14px'}>{itemData.email}</div>
                          ),
                        },
                        {
                          title: 'Telefone',
                          render: (itemData) => (
                            <div className={'table-cell-container publicsans-normal-black-14px'}>{itemData.phoneNumber}</div>
                          ),
                        },
                        {
                          title: 'Detalhes',
                          render: (itemData) => <HandleTeacherDetails teacherId={itemData.id} />,
                        },
                      ]}
                    />
                  ) : (
                    <NoContentContainer>Não há professores cadastrados nessa escola.</NoContentContainer>
                  )}
                </div>
              </div>
            )}

            {selectedTab === 'institutions' && (
              <div className={'redacoes-container-detalhamento-escola-style'}>
                <div className="search-filter-detalhamento-escola">
                  <div className="search-filtro-detalhamento-escola">
                    <HStack width={'100%'} alignItems="end">
                      <SearchComponent handleSearchInputChange={handleInstitutionSearchInputChange} />
                      <div>
                        <div
                          className="button-detalhamento-tema"
                          onClick={() => {
                            setCreateInstitutionData({ schoolId })
                            navigate('/criar-instituicao')
                          }}>
                          <div className="mainbutton-detalhamento-tema custom-button" style={{ height: '48px' }}>
                            <div className="content-2-detalhamento-tema">
                              <img
                                className="ic-add-detalhamento-tema"
                                src={'/img/ic-add-1.svg'}
                                alt="button-addStudent"
                                style={{ filter: 'brightness(0) invert(1)' }}
                              />
                              <Text
                                hideBelow={'md'}
                                className="main-button-detalhamento-tema custom-button-text publicsans-bold-white-14px">
                                {'Adicionar Instituição'}
                              </Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    </HStack>
                  </div>

                  {institutionsData.length > 0 ? (
                    <SharedCardPagination
                      data={institutions}
                      RenderComponent={InstitutionCard}
                      itemsPerPage={itemsPerPage}
                      props={{ fetchAllInstitutions, schoolId }}
                    />
                  ) : (
                    <NoContentContainer>Não há instituições cadastradas nessa escola.</NoContentContainer>
                  )}
                </div>
              </div>
            )}
          </VStack>
          <Box alignSelf={'start'} width={'100%'} mb={5} zIndex={'auto'} className="frame-2591-detalhamento-escola">
            <div className="title-cta-detalhamento-escola">
              <div className="title-detalhamento-escola">
                <h1 className="title-1-detalhamento-escola publicsans-normal-resolution-blue-24px">Dados da escola</h1>
              </div>
            </div>
            <div className="criao-atividade-detalhamento-escola">
              <div className="email-detalhamento-escola publicsans-normal-nevada-16px">{'Endereço'}</div>

              <div className="data-detalhamento-escola">
                <img className="x-2851-detalhamento-escola" src={'/img/location.svg'} alt="message-2851808" />
                <div className="publicsans-normal-nevada-14px">
                  {schoolData?.address ? `${schoolData?.address?.street} ${schoolData?.address?.number}` : 'Não informado'}
                  <p />
                  {schoolData?.address ? schoolData?.address?.zipCode : ''}
                  <p />
                  {schoolData?.address ? `${schoolData?.address?.city} ${schoolData?.address?.state}` : ''}
                  <p />
                  {schoolData?.address ? `${schoolData?.address?.complement}` : ''}
                </div>
              </div>

              <Button
                className="button-edit-endereco"
                onClick={modalAddressDisclosure.onOpen}
                isLoading={isPendingUseGetApiV1SchoolId}>
                <img className="ic-add-detalhamento-tema" src={'/img/edit-2851791-7.svg'} alt="ic-add" />
                <div className="main-button-detalhamento-tema publicsans-bold-resolution-blue-14px">{'Editar endereço'}</div>
              </Button>
            </div>
            <ModalEditAddressSchool
              schoolId={schoolId}
              schoolData={schoolData}
              loadingParent={loading}
              onClose={modalAddressDisclosure.onClose}
              isOpen={modalAddressDisclosure.isOpen}
              refetch={refetchSchool}
            />
          </Box>
        </HStack>
      </VStack>
    </div>
  )
}

export default SchoolDetails
