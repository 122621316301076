import type { CommentViewModel, CompleteEssayViewModel } from '@/services/types'
import { useToggleURLQueryParams } from '@/utils/useToogleQueryParam'
import { Stack, Text } from '@chakra-ui/react'
import { type MouseEvent, type TouchEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useReviewAreaWidth, useTouchDevice } from './EssayReviewArea.hooks'

export type TextSelection = {
  startPos: number
  endPos: number
}

type EssayTextReviewProps = {
  essay?: CompleteEssayViewModel
  onAddNewLine?: (data: TextSelection) => void
  onClickLine: (id: string) => void
  lines: CommentViewModel[]
  isViewOnlyMode: boolean
}

export type EssayTextReviewRef = {
  resetDraftLines: () => void
}

const lineBreakReplacer = ' --line-break-- '
const paddingX = 40
const stopTouchEvent = -1

export const EssayTextReview = forwardRef((props: EssayTextReviewProps, ref) => {
  const { essay, lines, onClickLine, onAddNewLine, isViewOnlyMode } = props
  const dimensions = useReviewAreaWidth()
  const [hoveredElement, setHoveredElement] = useState<string>()
  const textWithLineBreak = essay?.draft?.replaceAll('\n', lineBreakReplacer)
  const containerRef = useRef<HTMLDivElement>(null)
  const { hasURLQueryParams, toggleURLQueryParam } = useToggleURLQueryParams()
  const isCommentModeActive = hasURLQueryParams(['isCommentModeActive'])
  const isTouchDevice = useTouchDevice()

  const words = textWithLineBreak?.split(/[\s]+/)

  // desktop (non touchable devices) activate comment mode by default
  useEffect(() => {
    if (!isTouchDevice && !isCommentModeActive) {
      toggleURLQueryParam('isCommentModeActive', 'true')
    }
  }, [isCommentModeActive, toggleURLQueryParam, isTouchDevice])

  // Adding new line
  const [draftLine, setDraftLine] = useState<TextSelection>()
  const [isDrawing, setIsDrawing] = useState(false)

  const getIdFromEvent = (e: MouseEvent | TouchEvent): number => {
    if ('touches' in e) {
      if (!containerRef.current) return stopTouchEvent
      const touch = e.touches[0]
      const elementUnderTouch = document.elementFromPoint(touch.clientX, touch.clientY)
      return Number(elementUnderTouch?.getAttribute('id'))
    }
    return Number((e.target as HTMLParagraphElement).id)
  }

  const handleStart = (e: MouseEvent | TouchEvent) => {
    if (isViewOnlyMode || !isCommentModeActive) return

    const id = getIdFromEvent(e)
    if (!id || id === draftLine?.startPos || id === stopTouchEvent) return

    setIsDrawing(true)
    setDraftLine({ startPos: id, endPos: id })
  }

  const handleMove = (e: MouseEvent | TouchEvent) => {
    if (!isDrawing || !draftLine || isViewOnlyMode || !isCommentModeActive) return

    const id = getIdFromEvent(e)
    if (!id || id === draftLine?.startPos || id === stopTouchEvent) return

    const isSelectingBackwards = (draftLine?.startPos || stopTouchEvent) > id
    if (!isSelectingBackwards) return setDraftLine({ ...draftLine, endPos: id })

    const positions = [draftLine?.startPos, draftLine?.endPos, id]
    setDraftLine({ startPos: Math.min(...positions), endPos: Math.max(...positions) })
  }

  const handleEnd = () => {
    if (!draftLine || isViewOnlyMode) return
    if (selectionAlreadyExists(draftLine.startPos, draftLine.endPos, lines)) {
      toast.error('Remova a seleção existente para adicionar uma nova')
      setDraftLine(undefined)
      return
    }
    setIsDrawing(false)
    onAddNewLine?.(draftLine)
  }

  // Saved lines
  const handleLineClick = (e: MouseEvent<HTMLElement>) => {
    const id = (e.target as HTMLParagraphElement).id
    onClickLine(id)
  }

  useImperativeHandle(
    ref,
    (): EssayTextReviewRef => ({
      resetDraftLines: () => setDraftLine(undefined),
    }),
  )

  return (
    <Stack flex="1" alignItems="center" py={14} overflow="scroll">
      <Text
        ref={containerRef}
        width={dimensions.width - paddingX}
        maxW="800px"
        cursor={isViewOnlyMode ? 'default' : 'pointer'}
        userSelect="none"
        fontSize="xl"
        whiteSpace="pre-line"
        style={{ touchAction: isCommentModeActive ? 'none' : 'auto' }}
        onTouchMove={handleMove}
        onTouchEnd={handleEnd}
        onMouseDown={handleStart}
        onMouseMove={handleMove}
        onMouseUp={handleEnd}>
        {words?.map((word, index) => {
          const isDraftSelected = isSelected(index, draftLine?.startPos, draftLine?.endPos)
          const isLineSelected = lines.find((line) => isSelected(index, line.startPos || -1, line.endPos || -1))

          if (word.includes(lineBreakReplacer.trim())) return <br key={index} />

          if (isDraftSelected) {
            return (
              <Text
                as={'span'}
                key={index}
                id={index.toString()}
                p={1}
                onTouchMove={handleMove}
                display="inline-block"
                borderStartRadius={draftLine?.startPos === index ? 'full' : ''}
                borderEndRadius={draftLine?.endPos === index ? 'full' : ''}
                bgColor="#9595ff">
                {word}
              </Text>
            )
          }

          if (isLineSelected) {
            return (
              <Text
                as={'span'}
                key={index}
                id={isLineSelected.id}
                p={1}
                display="inline-block"
                cursor="pointer"
                borderStartRadius={isLineSelected.startPos === index ? 'full' : ''}
                borderEndRadius={isLineSelected.endPos === index ? 'full' : ''}
                onMouseEnter={() => setHoveredElement(isLineSelected.id)}
                onMouseLeave={() => setHoveredElement('')}
                onClick={handleLineClick}
                bgColor={hoveredElement === isLineSelected.id ? '#00e800' : '#84f684'}>
                {word}
              </Text>
            )
          }

          return (
            <Text key={index} onTouchStart={handleStart} as={'span'} display="inline-block" id={index.toString()} p={1}>
              {word}
            </Text>
          )
        })}
      </Text>
    </Stack>
  )
})

const isSelected = (index: number, startPos = -1, endPos = -1) => index >= startPos && index <= endPos
const selectionAlreadyExists = (startPos: number, endPos: number, selections: CommentViewModel[]) => {
  return selections.some(
    (selection) =>
      (selection?.startPos && selection.startPos >= startPos && selection.startPos <= endPos) ||
      (selection?.endPos && selection.endPos >= startPos && selection.endPos <= endPos),
  )
}
