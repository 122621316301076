/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-09 20:06:28 UTC)
 * OpenAPI spec version: v1
 */
import type { KeyValueDoubleViewModel } from './keyValueDoubleViewModel';
import type { KeyValueViewModel } from './keyValueViewModel';

export interface SchoolAnalyticsVieWModel {
  /** @nullable */
  institutionFinalGrades?: KeyValueDoubleViewModel[] | null;
  school?: KeyValueViewModel;
}
