import { Box, Image, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { type FileRejection, useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'

const maxFileSizeInMB = 5

type ThemeImageUpload = {
  onUpload: (image: string) => void
}
export const ThemeImageUpload = ({ onUpload }: ThemeImageUpload) => {
  const [preview, setPreview] = useState('')

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections.forEach((fileRejection) => {
      fileRejection.errors.forEach((error) => {
        if (error.code === 'file-too-large') {
          toast.error(`O arquivo "${fileRejection.file.name}" excede o tamanho máximo permitido de ${maxFileSizeInMB}MB`)
        }
      })
    })
  }

  const onDropAccepted = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const imageFile = acceptedFiles[0]
      const reader = new FileReader()

      reader.onload = () => {
        const result = reader.result as string
        setPreview(result)
        onUpload(result.split(',')[1])
      }

      if (imageFile) {
        reader.readAsDataURL(imageFile)
      } else {
        toast.error('Erro ao processar o arquivo de imagem.')
      }
    }
  }

  const dropzone = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
    multiple: false,
    maxSize: maxFileSizeInMB * 1024 * 1024,
    onDropRejected,
    onDropAccepted,
  })

  return (
    <>
      {preview ? (
        <Box {...dropzone.getRootProps()}>
          <input {...dropzone.getInputProps()} />
          <Image src={preview} alt="Imagem do tema" />
        </Box>
      ) : (
        <Box className="upload" sx={{ borderColor: dropzone.isDragActive && 'brand.200' }} {...dropzone.getRootProps()}>
          <input {...dropzone.getInputProps()} />
          <Image height="74px" src="/img/frame-5-1@2x.png" alt="arquivo de imagem" />
          <Box>
            {dropzone.isDragActive ? (
              <Text fontSize="sm">
                <Text as="span" mr={1} color="brand.200" fontWeight="bold">
                  Solte
                </Text>
                a imagem aqui
              </Text>
            ) : (
              <Text fontSize="sm">
                <Text as="span" mr={1} color="brand.200" fontWeight="bold">
                  Clique
                </Text>
                para escolher uma imagem
              </Text>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}
