import { getStudentActivityByIdApi } from '@/apis/activitityApi/activityApi'
import {
  createAvailableEssay,
  deleteEssayApi,
  editExitingEssay,
  fetchComments,
  fetchEssay,
  submitEssayForCorrection,
} from '@/apis/essayApi/essayApi'
import { listSupportMaterialApi } from '@/apis/themeApi/supportMaterialApi'
import submitConfirmImage from '@/assets/submit-confirm.png'
import { CommentDrawer } from '@/components/CommentDrawer'
import { Grades } from '@/components/Grades'
import Headbar from '@/components/Headbar'
import ZoomableImage from '@/components/ZoomableImage'
import { PencilIcon } from '@/components/icons/Pencil'
import { Modal } from '@/components/modal'
import { TextEditor } from '@/components/text-editor'
import { UploadArea } from '@/components/upload-area'
import CommonLayout from '@/layouts/common'
import { EssayImageReview } from '@/pages/EssayGrading/EssayReviewArea/EssayImageReview'
import { EssayTextReview } from '@/pages/EssayGrading/EssayReviewArea/EssayTextReview'
import Page from '@/pages/Page'
import { getApiV1ActivityStudentStudentId } from '@/services/activity'
import { deleteApiV1EssayEssayIdCommentsCommentId, postApiV1EssayEssayIdComments } from '@/services/essay'
import { getApiV1StudentActivityActivityId } from '@/services/student'
import type {
  ActivityViewModel,
  ActivityWithTotalsViewModel,
  CommentViewModel,
  CompleteEssayViewModel,
  EssayCompetencyViewModel,
  KeyValueViewModel,
  NewCommentViewModel,
} from '@/services/types'
import { relativeDate } from '@/utils/DateSatinizerInput'
import { calculateTotalGrades } from '@/utils/calculateTotalGrades'
import { essayStatus, returnEssayStatusClassName } from '@/utils/essayStatus'
import { useAddToHistory } from '@/utils/useNavigationHooks'
import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Fade,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Img,
  Link,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { GeneralComment } from './GeneralComment'
import { base64ToBlob, toBase64 } from './utils'

const essayIDErrorMessage = 'ID da readação não encontrada'

type DroppedFile = {
  id: string
  preview: string
  base64: string
}

// TODO: Replace with type from CO-650 when backend is complete
type SupportMaterial = {
  fileName: string
  id: string
  title: string
  type: number
  typeDescription: string
  value: string
}

const EssayCreation = () => {
  const search = useLocation().search
  const params = new URLSearchParams(search)
  const atv = params.get('atv')
  const id = params.get('id')
  const submitConfirmation = params.get('submitConfirmation')

  const navigate = useNavigate()

  const { isOpen: submitIsOpen, onOpen: submitOnOpen, onClose: submitOnClose } = useDisclosure()
  const { isOpen: discartIsOpen, onOpen: discartOnOpen, onClose: discartOnClose } = useDisclosure()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [activities, setActivities] = useState<ActivityWithTotalsViewModel[]>([])
  const [selectedActivity, setSelectedActivity] = useState<ActivityViewModel | null>(null)
  const [selectedActivityId, setSelectedActivityId] = useState('')
  const [supportMaterials, setSupportMaterials] = useState<SupportMaterial[]>([])
  const [selectedGroup, setSelectedGroup] = useState<KeyValueViewModel | null>()
  const [selectedGroupId, setSelectedGroupId] = useState('')
  const [essayContent, setEssayContent] = useState('')
  const [essay, setEssay] = useState<CompleteEssayViewModel>()
  const [essayImageUrl, setEssayImageUrl] = useState<string | null>(null)
  const [droppedImage, setDroppedImage] = useState<DroppedFile | null>(null)
  const [saveLoading, setSaveLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [comments, setComments] = useState<CommentViewModel[] | null>(null)
  const [competencies, setCompetencies] = useState<EssayCompetencyViewModel[] | null>(null)
  const [selectedCompetencyIndex] = useState(0)
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number } | null>(null)
  const [selectedCommentId, setSelectedCommentId] = useState<string | null>(null)
  const [zoom, setZoom] = useState(1)
  const [isSavingComment, setIsSavingComment] = useState(false)
  const [isDeletingComment, setIsDeletingComment] = useState(false)

  const isEssayFinalized = essay?.status === 40
  const isEssayInProgress = useMemo(() => !essay || (essay && essay.status === 10), [essay])
  const isBlankEssay = useMemo(() => !essay || essay.status === 0, [essay])
  const ableToSeeReview = useMemo(() => typeof essay !== 'undefined' && !!essay?.status && essay?.status >= 30, [essay])
  const isEssayImage = useMemo(() => !!essay?.attachment, [essay])
  const isEssayImageType = selectedActivity?.activityType !== 0
  const selectedCompetency = useMemo(
    () => (competencies ? competencies[selectedCompetencyIndex] : null),
    [selectedCompetencyIndex, competencies],
  )

  const isStudentCreatingEssay = selectedActivity && isEssayImageType && isBlankEssay && !droppedImage

  useAddToHistory()

  useEffect(() => {
    if (essay) {
      if (essay.attachment) {
        setEssayImageUrl(essay.attachment)
        getImageDimensions(essay.attachment)
      }
      if (ableToSeeReview) getComments()
    }
  }, [essay, ableToSeeReview])

  useEffect(() => {
    getActivityOptions()
    checkPreSelectedActivity()
    checkExistingEssay()
  }, [])

  useEffect(() => {
    getSupportMaterials()
    setDefaultGroup()
  }, [selectedActivity])

  useEffect(() => {
    if (droppedImage) {
      generateEssayImageUrl()
    }
  }, [droppedImage])

  // Comments
  const saveComment = async (comment: string, competencyId: string, parentId?: string | null) => {
    if (!essay?.id) return toast.error(essayIDErrorMessage)

    try {
      setIsSavingComment(true)

      const objectToBeSent: NewCommentViewModel = {
        comment,
        competencyId,
        parentId,
      }

      await postApiV1EssayEssayIdComments(essay.id, objectToBeSent)
      await getComments()

      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSavingComment(false)
    }
  }

  const deleteComment = async (commentId: string) => {
    if (!essay?.id) return toast.error('ID da redação não encontrada')

    try {
      setIsDeletingComment(true)
      await deleteApiV1EssayEssayIdCommentsCommentId(essay.id, commentId)
      await getComments()

      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsDeletingComment(false)
    }
  }

  const getActivityOptions = async () => {
    const studentId = localStorage.getItem('user_id')
    if (!studentId) return toast.error('Erro ao carregar o ID do estudante')
    const activitiesResult = await getApiV1ActivityStudentStudentId(studentId)
    const activities = activitiesResult.data.message
    if (!activities) return toast.error('Erro ao carregar as atividades do estudante')
    setActivities(activities)
  }

  async function checkPreSelectedActivity() {
    if (atv) {
      const activityResult = await getApiV1StudentActivityActivityId(atv)
      const activity = activityResult.data.message
      if (!activity) return toast.error('Erro ao carregar a atividade')
      setSelectedActivity(activity)
      setSelectedActivityId(atv)
    }
  }

  async function getFullActivityFromExitingEssay(activityId) {
    const activity = await getStudentActivityByIdApi(activityId)
    setSelectedActivity(activity)
  }

  async function checkExistingEssay() {
    if (id && !essay) {
      const exitingEssay = await fetchEssay(id)
      setEssay(exitingEssay)

      await getFullActivityFromExitingEssay(exitingEssay.activity.id)
      setSelectedActivityId(exitingEssay.activity.id)
      setCompetencies(exitingEssay.competencies)
    }
  }

  async function getSupportMaterials() {
    if (!selectedActivity?.theme?.id) return
    const supportMaterials = await listSupportMaterialApi(selectedActivity.theme.id)
    setSupportMaterials(supportMaterials)
  }

  async function setDefaultGroup() {
    if (!selectedActivity?.groups?.[0]?.id) return
    setSelectedGroup(selectedActivity.groups[0])
    setSelectedGroupId(selectedActivity.groups[0].id)
  }

  async function generateEssayImageUrl() {
    const blob = await base64ToBlob(droppedImage?.base64)
    const url = URL.createObjectURL(blob)
    setEssayImageUrl(url)
    getImageDimensions(url)
  }

  async function handleActivityChange(e) {
    const selectedActivityId = e.target.value
    setSelectedActivityId(selectedActivityId)

    const activity = await getStudentActivityByIdApi(selectedActivityId)
    setSelectedActivity(activity)

    const currentPath = document.location.pathname
    //addToHistory(currentPath)
    navigate(`${currentPath}?atv=${selectedActivityId}`)
  }

  function handleGroupChange(e) {
    const selectedGroupId = e.target.value
    setSelectedGroupId(selectedGroupId)

    const selectedGroup = selectedActivity?.groups?.find((group) => group.id === selectedGroupId)
    setSelectedGroup(selectedGroup)
  }

  async function createDraftEssay() {
    if (!selectedActivity?.id) return toast.error('ID da atividade selecionada não foi encontrada')
    if (!selectedActivity?.teacher?.id) return toast.error('ID do professor não foi encontrado')
    if (!selectedGroup?.id) return toast.error('ID do grupo não foi encontrado')

    try {
      setSaveLoading(true)

      const debug = {
        activityId: selectedActivity.id,
        groupId: selectedGroup.id,
        teacherId: selectedActivity.teacher.id,
        draft: !droppedImage ? essayContent : droppedImage.base64,
        isText: !droppedImage,
      }
      const draftEssayId = await createAvailableEssay(debug)

      const newEssay = await fetchEssay(draftEssayId)
      setEssay(newEssay)
      setDroppedImage(null)

      const currentPath = document.location.pathname
      //addToHistory(currentPath)
      navigate(`${currentPath}?id=${draftEssayId}`)
    } catch (error) {
      console.error(error)
    } finally {
      setSaveLoading(false)
    }
  }

  async function editEssay() {
    if (!essay?.id) return toast.error(essayIDErrorMessage)

    try {
      setSaveLoading(true)
      await editExitingEssay(essay.id, essayContent)

      const newEssay = await fetchEssay(essay.id)
      setEssay(newEssay)
    } catch (error) {
      console.error(error)
    } finally {
      setSaveLoading(false)
    }
  }

  async function submitEssay() {
    if (!essay?.id) return toast.error(essayIDErrorMessage)

    try {
      setSubmitLoading(true)
      await submitEssayForCorrection(essay.id)
      const newEssay = await fetchEssay(essay.id)
      setEssay(newEssay)
      submitOnClose()
      const currentPath = document.location.pathname
      //addToHistory(currentPath)
      navigate(`${currentPath}?atv=${selectedActivityId}&id=${essay.id}&submitConfirmation=true`)
    } catch (error) {
      console.error(error)
    } finally {
      setSubmitLoading(false)
    }
  }

  async function deleteEssay() {
    if (!essay?.id) return toast.error(essayIDErrorMessage)

    try {
      setDeleteLoading(true)
      await deleteEssayApi(essay.id)
      navigate(`/detalhamento-atividade/${selectedActivity?.id}`)
    } catch (error) {
      console.error(error)
    } finally {
      setDeleteLoading(false)
    }
  }

  async function onDropImage(files: File) {
    const newDropedFile = {
      id: String(files.lastModified),
      preview: URL.createObjectURL(files),
      base64: await toBase64(files),
    }

    setDroppedImage(newDropedFile)
  }

  async function getComments() {
    const comments = await fetchComments(id)
    setComments(comments)
  }

  function getImageDimensions(url: string) {
    const img = document.createElement('img') as HTMLImageElement

    img.onload = (event: Event) => {
      const target = event.target as HTMLImageElement
      setImageDimensions({ width: target.width, height: target.height })
    }
    img.src = url
  }

  async function saveAndSubmit() {
    await createDraftEssay()
    submitOnOpen()
  }

  function clearEssayImage() {
    setDroppedImage(null)
    setEssayImageUrl(null)
    setImageDimensions(null)
  }

  return (
    <Page authPage={true} allowedUserType={'student'}>
      <Stack spacing={0} height="100%">
        <Headbar />
        {essay && submitConfirmation && essay.status === 20 ? (
          <Center height="100%">
            <Stack textAlign="center">
              <Img width="300px" height="auto" src={submitConfirmImage} />
              <Text fontSize="32px" color="brand.200">
                Sua redação foi
              </Text>
              <Heading size="lg" color="brand.200">
                enviada para correção!
              </Heading>
              <Button
                onClick={() => {
                  navigate(`/detalhamento-atividade/${selectedActivity?.id}`)
                }}
                variant="primary"
                mt={12}>
                Ver redações
              </Button>
            </Stack>
          </Center>
        ) : (
          <HStack flexDir={{ base: 'column', md: 'row' }} justifyContent={'center'} width={'100%'}>
            <Box alignSelf={'start'} maxWidth={'1024px'} width={'100%'}>
              <Stack spacing={10} px={5} py={3} width={'100%'}>
                <Stack h={'100%'}>
                  {isEssayInProgress && (
                    <Box>
                      <Fade in={(!essay && !!essayContent) || (!essay && !!droppedImage)}>
                        <Flex justifyContent="space-between" height="24px">
                          <Text fontSize="xs" color="#828282">
                            Nenhuma alteração foi salva ainda
                          </Text>
                          <Button size="xs" onClick={createDraftEssay}>
                            {saveLoading ? <Spinner size="xs" marginRight="2" color="white" /> : null}
                            Salvar como rascunho
                          </Button>
                        </Flex>
                      </Fade>
                      {essay && (
                        <Fade in={!!essay}>
                          <Flex justifyContent="space-between" height="24px">
                            <Text fontSize="xs" color="#828282">
                              Salvo pela última vez há {relativeDate(essay.updatedAt)}
                            </Text>
                            <Button size="xs" onClick={editEssay} isDisabled={essay.draft === essayContent}>
                              {saveLoading ? <Spinner size="xs" marginRight="2" color="white" /> : null}
                              Salvar alterações
                            </Button>
                          </Flex>
                        </Fade>
                      )}
                    </Box>
                  )}

                  {essayImageUrl && imageDimensions && (!essay || !ableToSeeReview) && (
                    <Box position="relative">
                      <Center width={'100%'} height={'100%'}>
                        <ZoomableImage
                          essayImageUrl={essayImageUrl}
                          imageDimensions={imageDimensions}
                          setZoom={setZoom}
                          zoom={zoom}
                        />
                      </Center>
                      <Stack position="absolute" bottom="10px" right={['10px', '30px']}>
                        <Slider
                          aria-label="slider-ex-1"
                          defaultValue={1}
                          min={1}
                          max={3}
                          step={0.1}
                          value={zoom}
                          maxH="64"
                          minH={'32'}
                          orientation="vertical"
                          onChange={(value) => setZoom(value)}
                          marginBottom={['0px', '32px']}>
                          <SliderTrack>
                            <SliderFilledTrack />
                          </SliderTrack>
                          <SliderThumb />
                        </Slider>
                        {isBlankEssay && (
                          <>
                            <UploadArea onDrop={onDropImage} essayImageUrl />
                            <Button
                              borderRadius="full"
                              width="60px"
                              height="60px"
                              background="white"
                              shadow="lg"
                              onClick={clearEssayImage}>
                              <Icon as={IoMdClose} width="25px" height="25px" />
                            </Button>
                          </>
                        )}
                      </Stack>
                    </Box>
                  )}
                  {ableToSeeReview ? (
                    comments &&
                    selectedCompetency && (
                      <Stack
                        width="100%"
                        backgroundColor="#fff"
                        overflow="auto"
                        shadow="lg"
                        borderRadius="xl"
                        height="572px"
                        overflowY="auto"
                        padding={5}>
                        <>
                          <CommentDrawer
                            isOpen={isOpen}
                            allComments={comments}
                            competencies={competencies || []}
                            selectedCommentId={selectedCommentId}
                            onCloseDrawer={onClose}
                            isViewOnlyMode={isEssayFinalized}
                            action={{
                              save: saveComment,
                              delete: deleteComment,
                              isDeleting: isDeletingComment,
                              isSaving: isSavingComment,
                            }}
                          />
                          {isEssayImage && imageDimensions && (
                            <EssayImageReview
                              isViewOnlyMode={true}
                              imageUrl={essay?.attachment || ''}
                              lines={comments}
                              onClickLine={(id) => {
                                setSelectedCommentId(id)
                                onOpen()
                              }}
                            />
                          )}
                          {!isEssayImage && (
                            <EssayTextReview
                              isViewOnlyMode={true}
                              essay={essay}
                              lines={comments}
                              onClickLine={(id) => {
                                setSelectedCommentId(id)
                                onOpen()
                              }}
                            />
                          )}
                        </>
                      </Stack>
                    )
                  ) : isEssayInProgress ? (
                    !droppedImage && !essayImageUrl ? (
                      <TextEditor onChange={setEssayContent} essay={essay} />
                    ) : (
                      <></>
                    )
                  ) : (
                    essay &&
                    !essay.attachment && (
                      <Stack
                        backgroundColor="#fff"
                        borderRadius="12px"
                        padding="12px"
                        border="1px solid #F3F5FF"
                        boxShadow="0px 4px 16px 0px rgba(0, 0, 0, 0.04)"
                        height="100%"
                        alignItems="start"
                        mt={5}>
                        <Text color="#828282" whiteSpace="break-spaces" fontSize="16px">
                          {essay.draft}
                        </Text>
                      </Stack>
                    )
                  )}
                </Stack>

                {ableToSeeReview && essay?.id && <GeneralComment essayId={essay.id} />}

                {isStudentCreatingEssay && (
                  <Stack spacing={9}>
                    <Heading size={'md'}>Envie uma foto da sua redação</Heading>
                    <UploadArea onDrop={onDropImage} essayImageUrl={essayImageUrl} />
                  </Stack>
                )}

                {isEssayInProgress && (
                  <HStack width={'100%'} height="100%" gap={5} mb={5} flexDir={{ base: 'column-reverse', md: 'row' }}>
                    <Button variant="outline" width="100%" onClick={essay ? discartOnOpen : () => navigate(-1)}>
                      Descartar
                    </Button>

                    <Button
                      isDisabled={!(!droppedImage || !essayContent)}
                      variant="primary"
                      width="100%"
                      onClick={!essay ? saveAndSubmit : submitOnOpen}>
                      {saveLoading ? <Spinner size="xs" marginRight="2" color="white" /> : null}
                      {!essay && 'Salvar e '}Enviar redação
                    </Button>
                  </HStack>
                )}
              </Stack>
            </Box>

            <Box alignSelf={['center', 'start']} width={{ base: '100%', md: 'inherit' }} minW={'250px'} maxWidth={'500px'}>
              <Stack backgroundColor={'#fff'} height="100%" spacing={4} p={10}>
                {essay && (
                  <>
                    <Heading size="xs">Status da redação</Heading>
                    <Center p={1} className={`${returnEssayStatusClassName(essay.status)}`} w={'100%'}>
                      <Text fontSize={'sm'}>{essayStatus[essay.status].label}</Text>
                    </Center>
                  </>
                )}
                <Heading size="xs">Atividade</Heading>
                {essay?.activity?.name && <Text size="xs">{essay.activity.name}</Text>}
                {!essay && (
                  <Select
                    backgroundColor="#F9FAFF"
                    color="brand.100"
                    placeholder="Selecionar a atividade"
                    border="1px solid #F9FAFF"
                    borderRadius="8px"
                    variant="filled"
                    size="lg"
                    onChange={handleActivityChange}
                    value={selectedActivityId}>
                    {activities.map((activity) => (
                      <option key={activity.id} value={activity.id}>
                        {activity.subject}
                      </option>
                    ))}
                  </Select>
                )}
                {!essay && selectedActivity?.groups?.length && (
                  <>
                    <Heading size="xs">Turma</Heading>
                    {!essay && (
                      <Select
                        backgroundColor="#F9FAFF"
                        color="brand.100"
                        placeholder="Selecionar turma"
                        border="1px solid #F9FAFF"
                        borderRadius="8px"
                        variant="filled"
                        size="lg"
                        onChange={handleGroupChange}
                        value={selectedGroupId}>
                        {selectedActivity.groups.map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  </>
                )}
                {selectedActivity && (
                  <Stack spacing={3}>
                    <Heading size="xs">Tema</Heading>
                    <Text size="xs">{selectedActivity?.theme?.name}</Text>
                    <HStack my={4}>
                      <PencilIcon />
                      <Stack>
                        <Heading size="xs">Descrição da atividade</Heading>
                        <Text size="xs" color="#828282">
                          Entenda um pouco mais sobre a atividade
                        </Text>
                      </Stack>
                    </HStack>

                    <Text size="xs">{selectedActivity.description}</Text>
                    {supportMaterials?.length > 0 && (
                      <>
                        <Heading size="xs" mt={5}>
                          Materiais de apoio
                        </Heading>
                        <VStack p="16px" gap={4} borderRadius="md" backgroundColor="brand.500">
                          {supportMaterials.map((supportMaterial) => (
                            <Link
                              key={supportMaterial.fileName}
                              color="gray.500"
                              display="flex"
                              gap={2}
                              href={supportMaterial.value}
                              download>
                              <Image src={'/img/paper-upload.svg'} marginLeft={'16px'} />
                              {supportMaterial.fileName}
                            </Link>
                          ))}
                        </VStack>
                      </>
                    )}

                    {ableToSeeReview && (
                      <>
                        <Divider my={7} color="#E3E3E3" />

                        <Grades competencies={competencies || []} totalGrade={calculateTotalGrades(competencies || [])} />

                        <Divider my={7} color="#E3E3E3" />

                        <Heading size="xs">Corretor</Heading>

                        {essay?.teacher?.name ? (
                          <HStack>
                            <Avatar name={essay.teacher.name} />
                            <Text size="xs">{essay.teacher.name}</Text>
                          </HStack>
                        ) : (
                          <HStack>
                            <Avatar name={selectedActivity?.school?.name || 'Nome do professor ou escola não encontrado'} />
                            <Text size="xs">
                              {selectedActivity?.school?.name || 'Nome do professor ou escola não encontrado'}
                            </Text>
                          </HStack>
                        )}
                      </>
                    )}
                  </Stack>
                )}
              </Stack>
            </Box>
          </HStack>
        )}
      </Stack>

      {isEssayInProgress && (
        <>
          <Modal
            title={'Enviar redação'}
            isOpen={submitIsOpen}
            onOpen={submitOnOpen}
            onClose={submitOnClose}
            onSubmit={submitEssay}
            isLoading={submitLoading}
            submitLabel={'Enviar para correção'}
            body={'Uma vez que a redação é submetida para correção, você não poderá mais alterá-la. Deseja continuar?'}
          />
          <Modal
            title={'Descartar redação'}
            isOpen={discartIsOpen}
            onOpen={discartOnOpen}
            onClose={discartOnClose}
            onSubmit={deleteEssay}
            isLoading={deleteLoading}
            submitLabel={'Descartar'}
            body={'A redação será excluida e essa operação não pode ser desfeita. Deseja continuar?'}
          />
        </>
      )}
    </Page>
  )
}

EssayCreation['Layout'] = CommonLayout
export default EssayCreation
